import { createContext, useContext, useRef, useState } from "react";
import WebinarManager from "../../Managers/webinar";
import ResourcesManager from "../../Managers/Resources";

import { Webinar_status } from "../../AppConstants/TypeConstants";
import { DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";
import { useSpeakerContext } from "../SpeakerContext";

import { USER_FEEDBACK_COLLECTION } from "../../AppConstants/CollectionConstants";

// Firebase
import firebase, { database, firestore } from "../../firebase/firebase";
import { UserContext } from "../UserContext/UserContextProvider";
import { getOS, getSystemID } from "../../utils/getPlatformInfo";
const uniqid = require("uniqid");

const webinar_limit = 20;
export const webinarContext = createContext([]);

export default function WebinarContextProvider(props) {
  const { getMultipleSpeakersData } = useSpeakerContext();
  const { user } = useContext(UserContext);
  const [webinars, setAllWebinars] = useState({ data: null, lastDocRef: null });
  const disconnectRef = useRef(null);
  const userDisconnectRef = useRef(null);

  const getResources = async (resourcesArray) => {
    try {
      let resources = [];
      let promiseArr = [];

      for (let resourceId of resourcesArray) {
        promiseArr.push(ResourcesManager._get.getResourceById(resourceId));
      }

      const resolved = await Promise.allSettled(promiseArr);

      for (let doc of resolved) {
        if (doc.status === "fulfilled") {
          resources.push(doc.value);
        }
      }
      return resources;
    } catch (error) {
      console.error("Error :: ", error);
      throw error;
    }
  };

  const getAllWebinar = async (fetchParameters) => {
    try {
      let _data = [];
      let res = await WebinarManager._get.getSortedWebinars(
        [],
        "startTime",
        DESCENDING,
        null,
        webinar_limit,
        false
      );
      //   get all speakers
      _data = await Promise.all(
        res.data
          .filter((webinar) => webinar.status !== Webinar_status.DISABLED)
          .map(async (webinar) => {
            let speakersList = [];
            if (webinar.speakers.length !== 0) {
              speakersList = await getMultipleSpeakersData(webinar.speakers);
            }
            return {
              ...webinar,
              speakersList: speakersList,
            };
          })
      );
      let newObj = { data: _data, lastDocRef: res.lastDocRef };
      setAllWebinars(newObj);
    } catch (error) {}
  };

  const addWebinarTimespent = async (
    userID,
    webinarID,
    agendaID,
    status,
    nextAgendaItemTime,
    logout = false
  ) => {
    try {
      if (
        (!disconnectRef || !disconnectRef.current) &&
        status === Webinar_status.ENDED
      ) {
        return;
      }
      await WebinarManager._post.webinarTimeSpentAnalytics(
        userID,
        webinarID,
        agendaID,
        nextAgendaItemTime
      );
      await onDisconnectListener(userID, webinarID, agendaID, logout);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserStateInWebinar = async (
    userID,
    userState,
    webinarID,
    status = "offline",
    logout = false
  ) => {
    try {
      if (
        (!userDisconnectRef || !userDisconnectRef.current) &&
        status === Webinar_status.ENDED
      ) {
        return;
      }
      let systemID = getSystemID();
      let userOS = getOS();

      let obj = {
        webinarID: webinarID,
        userState: userState,
        status: status,
        lastChange: firebase.database.ServerValue.TIMESTAMP,
        userID: userID,
        systemID: systemID,
        os: userOS,
      };

      let databaseRef = database.ref(
        "webinarLiveStatus/" + webinarID + "/" + userID + "_" + systemID
      );
      await databaseRef.update(obj);
      await onDisconnectUserListener(obj, logout);
    } catch (error) {
      console.error(error);
    }
  };

  const onDisconnectUserListener = async (data, logout) => {
    const { userID, webinarID, systemID } = data;
    try {
      if (!userID || !webinarID) {
        throw new Error({
          code: "UserIDWasNull",
          message: "Please try again later",
        });
      }

      if (logout) {
        if (userDisconnectRef.current) {
          await userDisconnectRef.current.cancel();
          userDisconnectRef.current = null;
        }
        return;
      } else {
        if (userDisconnectRef.current) {
          await userDisconnectRef.current.cancel();
          userDisconnectRef.current = null;
        }
      }

      let databaseRef = database.ref(
        "webinarLiveStatus/" + webinarID + "/" + userID + "_" + systemID
      );

      userDisconnectRef.current = databaseRef.onDisconnect();
      await userDisconnectRef.current.set({ ...data, status: "offline" });

      return;
    } catch (error) {
      throw error;
    }
  };

  const onDisconnectListener = async (
    userID,
    webinarID,
    agendaID,
    logout = false
  ) => {
    try {
      if (!userID || !webinarID || !agendaID) {
        throw new Error({
          code: "UserIDWasNull",
          message: "Please try again later",
        });
      }

      if (logout) {
        if (disconnectRef.current) {
          await disconnectRef.current.cancel();
          disconnectRef.current = null;
        }
        return;
      } else {
        if (disconnectRef.current) {
          await disconnectRef.current.cancel();
          disconnectRef.current = null;
        }
      }
      let systemID = getSystemID();

      let obj = {
        webinarID: webinarID,
        agendaID: agendaID,
        lastChange: firebase.database.ServerValue.TIMESTAMP,
        userID: userID,
        systemID: systemID,
      };

      let databaseRef = database.ref(
        "analytics/" + webinarID + "/" + userID + "_" + systemID
      );

      disconnectRef.current = databaseRef.onDisconnect();
      await disconnectRef.current.set({
        state: "offline",
        ...obj,
      });

      return;
    } catch (error) {
      throw error;
    }
  };

  const createUserFeedback = async (webinarID, selectedResponses) => {
    try {
      if (!user || !webinarID) {
        return;
      }
      const feedbackId = uniqid("feedback-");

      const docId = `${webinarID}_${feedbackId}`;
      const docRef = firestore.collection(USER_FEEDBACK_COLLECTION).doc(docId);

      const feedback = {
        userId: user.uid,
        userName: user.displayName,
        eventId: webinarID,
        responses: [...selectedResponses],
        feedbackId: docId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };
      let isUserSubmittedFeedback= await WebinarManager.userFeedbackManager._get.isUserFeedbackExists(user.uid,webinarID);
      if(isUserSubmittedFeedback) {
        return false;
      }
      
      await firestore.runTransaction(async (transaction) => {
        transaction.set(docRef, feedback);
      });
      return feedbackId;
    } catch (error) {
      console.error("Error :: ", error);
      throw error;
    }
  };

  return (
    <webinarContext.Provider
      value={{
        webinars,
        getAllWebinar,
        createUserFeedback,
        getResources,
        addWebinarTimespent,
        updateUserStateInWebinar,
      }}
    >
      {props.children}
    </webinarContext.Provider>
  );
}
