//Imports
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	DISLIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	SERIES_COLLECTION,
	SEASON_COLLECTION,
	USER_COLLECTION,
	REELS_COLLECTION,
	COURSE_CHAPTERS_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { ScorePoints } from '../../../AppConstants/ScorePoints';
import { SourceType, VideoType, LikeType, LIKE_ACTION_TYPE, ContentType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const createLike = async (targetType, videoId, userId, isDislikeAction = false) => {
	try {
		const COLLECTION = {
			[ContentType.VIDEO]: VIDEO_COLLECTION,
			[ContentType.SERIES]: SERIES_COLLECTION,
			[ContentType.SEASON]: SEASON_COLLECTION,
			[ContentType.REEL]: REELS_COLLECTION,
			[ContentType.CHAPTER]: COURSE_CHAPTERS_COLLECTION,
		};
		let targetCollection = COLLECTION[targetType];
		const docRef = firestore.collection(targetCollection).doc(videoId);
		const LikeRef = firestore.collection(LIKES_COLLECTION).doc(`${userId}+${videoId}`);

		const userRef = firestore.collection(USER_COLLECTION).doc(userId);

		const like = await firestore.runTransaction(async (transaction) => {
			let doc = await transaction.get(docRef);
			let likeDoc = await transaction.get(LikeRef);

			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No VideoId Found'
				};
				throw err;
			}
			if (likeDoc.exists) {
				let userDoc = await transaction.get(userRef);
				if (!userDoc.exists) {
					let err = {
						code: 'NotValidId',
						message: 'No User Found'
					};
					throw err;
				}

				let userData = userDoc.data();

				let oldData = likeDoc.data();
				let oldActionType = oldData.actionType ? oldData.actionType : LIKE_ACTION_TYPE.LIKE;
				let newData = {
					id: `${userId}+${videoId}`,
					targetId: videoId,
					type: targetType,
					user: userId,
					actionType: isDislikeAction ? LIKE_ACTION_TYPE.DISLIKE : LIKE_ACTION_TYPE.LIKE,
					timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					date: new Date().getTime()
				};

				if (oldActionType == LIKE_ACTION_TYPE.DISLIKE && !isDislikeAction) {
					transaction.update(LikeRef, newData);
					transaction.update(docRef, {
						dislikes: firebase.firestore.FieldValue.increment(-1),
						likes: firebase.firestore.FieldValue.increment(1)
					});
					return doc.data().likes ? doc.data().likes + 1 : 1;
				} else if (oldActionType == LIKE_ACTION_TYPE.LIKE && isDislikeAction) {
					transaction.update(LikeRef, newData);
					transaction.update(docRef, {
						dislikes: firebase.firestore.FieldValue.increment(1),
						likes: firebase.firestore.FieldValue.increment(-1)
					});
					return doc.data().dislikes ? doc.data().dislikes + 1 : 1;
				} else if (oldActionType == LIKE_ACTION_TYPE.DISLIKE && isDislikeAction) {
					transaction.delete(LikeRef);
					transaction.update(docRef, {
						dislikes: firebase.firestore.FieldValue.increment(-1)
					});
					return doc.data().dislikes ? doc.data().dislikes - 1 : 0;
				} else if (oldActionType == LIKE_ACTION_TYPE.LIKE && !isDislikeAction) {
					transaction.delete(LikeRef);
					transaction.update(docRef, {
						likes: firebase.firestore.FieldValue.increment(-1)
					});

					if (oldData.points) {
						// Removing points when disliked
						transaction.update(userRef, {
							score: userData.score
								? firebase.firestore.FieldValue.increment(-Math.abs(oldData.points))
								: 0
						});
						// console.log('Video Disliked');
					}

					return doc.data().likes ? doc.data().likes - 1 : 0;
				}
			} else {
				let userDoc = await transaction.get(userRef);
				if (!userDoc.exists) {
					let err = {
						code: 'NotValidId',
						message: 'No User Found'
					};
					throw err;
				}

				let userData = userDoc.data();

				transaction.set(LikeRef, {
					id: `${userId}+${videoId}`,
					targetId: videoId,
					type: targetType,
					user: userId,
					actionType: isDislikeAction ? LIKE_ACTION_TYPE.DISLIKE : LIKE_ACTION_TYPE.LIKE,
					timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					date: new Date().getTime(),
					points: ScorePoints.LIKE
				});
				if (isDislikeAction) {
					transaction.update(docRef, {
						dislikes: firebase.firestore.FieldValue.increment(1)
					});
					return doc.data().dislikes ? doc.data().dislikes + 1 : 1;
				} else {
					transaction.update(docRef, {
						likes: firebase.firestore.FieldValue.increment(1)
					});

					// Added points when liked
					transaction.update(userRef, {
						score: userData.score
							? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.LIKE))
							: ScorePoints.LIKE
					});

					// console.log('LIKE ADDED');

					return doc.data().likes ? doc.data().likes + 1 : 1;
				}
			}
		});
		return like;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createLike
};

export default post;
