import { USER_FEEDBACK_COLLECTION } from "../../../AppConstants/CollectionConstants";

import { firestore } from "../../../firebase/firebase";


const isUserFeedbackExists = async (userId,eventId) => {
  try {

    let userFeedbackRef = firestore
      .collection(USER_FEEDBACK_COLLECTION)
      .where("userId", "==", userId)
      .where("eventId","==", eventId);
    
    let userFeedbackQuery = await userFeedbackRef.get();
    if (!userFeedbackQuery.empty) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

const get = {
  isUserFeedbackExists
};

export default get;
