import { POLLS_COLLECTION } from '../../../AppConstants/CollectionConstants';
import { ASCENDING, DESCENDING, TIMESTAMP } from '../../../AppConstants/SortConstants';

import firebase, { firestore } from '../../../firebase/firebase';

let pollListenerRef = null;
const getPoll = async (pollId) => {
	try {
		const docRef = firestore.collection(POLLS_COLLECTION).doc(pollId);
		const doc = await docRef.get();
		if (!doc.exists) {
			const error = {
				code: 'NotValidId',
				message: 'No pollId Found'
			};

			throw error;
		}

		return doc.data();
		// const _data = await firestore.runTransaction(async (transaction) => {
		//   const doc = await transaction.get(docRef);
		//   if (!doc.exists) {
		//     const error = {
		//       code: "NotValidId",
		//       message: "No pollId Found",
		//     };

		//     throw error;
		//   }

		//   let _data = doc.data();

		//   return _data;
		// });

		// return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getWebinarPolls = async (webinarId) => {
	try {
		let docRef = firestore.collection(POLLS_COLLECTION).where('webinarId', '==', webinarId);
		docRef = docRef.orderBy(TIMESTAMP, DESCENDING);

		const query = await docRef.get();
		if (query.empty) {
			return [];
		}

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});

		// sort the response with index
		_data.sort((a, b) => {
			return a.index - b.index;
		});

		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const attachPollListener = (webinarId, callback = () => console.log('noFunFound')) => {
	const ref = firestore.collection(POLLS_COLLECTION).where('webinarId', '==', webinarId).orderBy(TIMESTAMP,ASCENDING);;

	pollListenerRef = ref.onSnapshot(
		(query) => {
			if (query.empty) {
				callback([]);
			}
			let _data = query.docs.map((doc) => doc.data());
			callback(_data);
		},
		(err) => {
			console.error(err);
			callback(null, err);
		}
	);
};

const removePollListener = () => {
	if (pollListenerRef) {
		pollListenerRef();
	}
};

const get = {
	getPoll,
	getWebinarPolls,
	attachPollListener,
	removePollListener
};

export default get;
