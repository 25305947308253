import { createContext, useState } from "react";

export const ModalContext = createContext([]);

export default function ModalContextProvider(props) {
  const [showModalOverlay, setShowModalOverlay] = useState(false);

  const handleModalShowOverlay = (value) => {
    setShowModalOverlay(value);
  };

  return (
    <ModalContext.Provider value={{ showModalOverlay, handleModalShowOverlay }}>
      {props.children}
    </ModalContext.Provider>
  );
}
