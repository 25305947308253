//Imports
import { SPEAKERS_COLLECTION } from '../../AppConstants/CollectionConstants';
import { ContentType } from '../../AppConstants/TypeConstants';
// Firebase
import firebase, { firestore } from '../../firebase/firebase';
const uniqid = require('uniqid');

const createSpeaker = async ({ name, photoUrl, therapy, designation, degree, profileLine, featuredIndex }) => {
	try {
		const speakerId = uniqid('speaker-');
		const docRef = firestore.collection(SPEAKERS_COLLECTION).doc(speakerId);
		const speaker = {
			id: speakerId,
			name: name || null,
			photoUrl: photoUrl || null,
			therapy: therapy || null,
			designation: designation || null,
			degree: degree || null,
			profileLine: profileLine || null,
			videoCount: 0,
			followerCount: 0,
			featuredIndex: featuredIndex || -1,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		await firestore.runTransaction(async (transcation) => {
			transcation.set(docRef, speaker);
		});
		return speakerId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createSpeaker
};

export default post;
