import React from "react";
import { ASSETS_BASE_URL } from "../../AppConstants/Constants";
import LoaderImg from "../../assets/images/Loader.gif";
import styles from "./RestrictedMessage.module.scss";

const locationImg = `${ASSETS_BASE_URL}/images/restrictedScreen/locationImage.png`;

const RestrictedMessage = () => {
  return (
    <div style={{}} className={styles.container}>
      <div className={`box ${styles.box}`}>
        <img src={locationImg} className={styles.locationImg} alt="" />
        <div className={styles.text}>
          This application is not available in your region. It will be live
          soon.
        </div>
      </div>
    </div>
  );
};

export default RestrictedMessage;

export const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderImg} alt="loader" width="70" />
    </div>
  );
};
