//Imports
import { USER_NOTES } from "../../../AppConstants/CollectionConstants";

// Firebase
import { firestore } from "../../../firebase/firebase";

let NotesListenerRef = null;
let UserNotesListenerRef = null;

const getNote = async (targetId, userId) => {
  try {
    if (!userId && !targetId) {
      throw new Error("Please provide a userId and targetId");
    }

    const noteId = `${userId}_${targetId}`;
    const docRef = firestore.collection(USER_NOTES).doc(noteId);

    const _data = await docRef.get();

    return _data.data();
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const attachNotesListener = (
  targetId,
  userId,
  callback = () => console.log("noFunFound")
) => {
  const noteId = `${userId}_${targetId}`;
  const ref = firestore.collection(USER_NOTES).doc(noteId);
  NotesListenerRef = ref.onSnapshot(
    (query) => {
      if (!query.exists) {
        callback([]);
        return;
      }
      let _data = query.data();
      callback(_data);
    },
    (err) => {
      callback(null, err);
    }
  );
};

const attachUserNotesListener = (userId, callback = () => console.log('noFunFound')) => {
	const ref = firestore.collection(USER_NOTES).where('userId', '==', userId);
	UserNotesListenerRef = ref.onSnapshot(
		(query) => {
			if (query.empty) {
				callback([]);
			}
			let _data = query.docs.map((doc) => doc.data());
			callback(_data);
		},
		(err) => {
			callback(null, err);
		}
	);
};

const removeNotesListener = () => {
  if (NotesListenerRef) {
    NotesListenerRef();
  }
};
const removeUserNotesListener = () => {
  if (UserNotesListenerRef) {
    UserNotesListenerRef();
  }
};

const get = {
  getNote,
  attachNotesListener,
  removeNotesListener,
  attachUserNotesListener,
  removeUserNotesListener
};

export default get;
