import { USER_COLLECTION } from "../../../AppConstants/CollectionConstants";
import { firestore } from "../../../firebase/firebase";

const deleteAvatar = async (userId) => {
  try {
    const docRef = await firestore
      .collection(USER_COLLECTION)
      .doc(userId)
      .get();
    if (!docRef.exists) {
      let err = {
        code: "NotValidId",
        message: "No User Found",
      };
      throw err;
    }

    return firestore.runTransaction(async (t) => {
      return t.update(firestore.collection(USER_COLLECTION).doc(userId), {
        avatar: null,
      });
    });
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const _delete = {
  deleteAvatar,
};

export default _delete;
