//Imports
import { SERIES_COLLECTION } from '../../../AppConstants/CollectionConstants';
import { Video_States } from '../../../AppConstants/TypeConstants';

// Firebase
import { firestore, database } from '../../../firebase/firebase';

const getSeriesById = async (seriesId) => {
	try {
		const docRef = firestore.collection(SERIES_COLLECTION).doc(seriesId);
		const doc = await docRef.get();

		if (!doc.exists) {
			const error = {
				code: 'NotValidId',
				message: 'No SeriesId Found'
			};
			throw error;
		}

		if (doc.data().status !== Video_States.READY) {
			throw new Error('Series Not ready');
		}

		return doc.data();
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

// old
// const getTopShows = async () => {
//   try {
//     let allSeriesIds_array = [];
//     const dbRef = database.ref("backstageV2/TrendingSeries");
//     let dbRes = await dbRef.get();

//     if (!dbRes.exists()) {
//       return [];
//     }

//     dbRes.forEach((childSnapshot) => {
//       allSeriesIds_array.push(childSnapshot.val());
//     });

//     const data = await Promise.allSettled(
//       allSeriesIds_array.map((seriesId) => getSeriesById(seriesId))
//     );
//     let _data = data.map((doc) => doc.status === "fulfilled" && doc.value);
//     return _data;
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };
const getTopShows = async () => {
	try {
		let docRef = await database.ref('backstageV2/Top10Shows').limitToFirst(10).once('value');
		if (!docRef.exists()) {
			return [];
		} else {
			return docRef.val();
			//   let seriesIds = docRef.val();

			//   const promiseArr = [];
			//   for (let seriesId of seriesIds) {
			//     promiseArr.push(
			//       firestore.collection(SERIES_COLLECTION).doc(seriesId).get()
			//     );
			//   }

			//   const resolved = await Promise.allSettled(promiseArr);

			//   let returnData = [];
			//   for (let doc of resolved) {
			//     if (doc.status === "fulfilled") {
			//       if (
			//         doc.value.exists &&
			//         doc.value.data().status === Video_States.READY
			//       ) {
			//         returnData.push(doc.value.data());
			//       }
			//     }
			//   }

			//   return returnData;
		}
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const get = {
	getSeriesById,
	getTopShows
};

export default get;
