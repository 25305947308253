import CourseProgressionManager from '.';
import {
	ACADEMY_PROGRESSION_COLLECTION,
	COURSE_COLLECTION,
	STUDENTS_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { ErrCode_AlreadyExists, ErrCode_DocNotFound } from '../../../AppConstants/ErrorCodes';
import firebase, { firestore } from '../../../firebase/firebase';

const createStudentProgression = async (courseId, studentId, utmParams = {}, data = {}) => {
	try {
		const id = `${courseId}_${studentId}`;
		let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
		let docRef = firestore.collection(ACADEMY_PROGRESSION_COLLECTION).doc(id);
		let docData = await docRef.get();
		if (docData.exists) {
			let err = {
				code: ErrCode_AlreadyExists,
				message: 'Progression already exists'
			};
			throw err;
		}

		let studentRef = firestore.collection(STUDENTS_COLLECTION).doc(studentId);
		let studentDoc = await studentRef.get();
		if (!studentDoc.exists) {
			let err = {
				code: ErrCode_DocNotFound,
				message: 'Student not found'
			};

			throw err;
		}

		let courseRef = firestore.collection(COURSE_COLLECTION).doc(courseId);
		let courseDoc = await courseRef.get();
		if (!courseDoc.exists) {
			let err = {
				code: ErrCode_DocNotFound,
				message: 'Course not found'
			};

			throw err;
		}

		let chapterProgression = {
			id,
			courseId,
			studentId,
			earnedPoints: 0,
			downloadedCertificate: null,
			certificateUrl: null,
			chapters: {},
			resourcesDownloaded: {},
			courseCompleted: false,
			timestamp: serverTimestamp,
			utmParams,
			prerequisite: data
		};

		return firestore.runTransaction(async (t) => {
			t.set(docRef, chapterProgression);
			t.update(studentRef, {
				coursesCurrentlyEnrolled: firebase.firestore.FieldValue.increment(1)
			});
			t.update(courseRef, {
				attendenceCount: firebase.firestore.FieldValue.increment(1)
			});

			return id;
		});
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const checkForContentAlreadyWatched = async (chaptersData = [], studentId) => {
	try {
		if (!chaptersData.length) {
			return;
		}

		chaptersData = chaptersData.filter((chapter) => chapter['id'].includes('course-chapter'));

		if (!chaptersData.length) {
			return;
		}


		const allocatePointsForChapters = [];

		for (let chapter of chaptersData) {
			const { targetData } = chapter;
			if (!targetData) {
				continue;
			}

			const { collection, targetField, targetId, userField } = targetData;
			const docuementRef = firestore
				.collection(collection)
				.where(targetField, '==', targetId)
				.where(userField, '==', studentId);
			const documentDoc = await docuementRef.get();
			if (documentDoc.empty) {
				continue;
			}

			const docData = documentDoc.docs[0].data();
			if (!docData) {
				continue;
			}

			// Allocate Points
			allocatePointsForChapters.push(chapter);
		}


		if (!allocatePointsForChapters.length) {
			return;
		}

		for (let chapter of allocatePointsForChapters) {
			await CourseProgressionManager._put.updateChapterProgression({
				id: `${chapter['courseId']}_${studentId}`,
				studentId: studentId,
				chapterId: chapter['id'],
				courseId: chapter['courseId'],
				data: {
					completed: true,
					completeDuration: 0,
					totalDuration: chapter['duration'],
					prealloted: true,
					updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
				},
				override: false
			});
		}
	} catch (err) {
		console.error(err);
	}
};

const post = {
	createStudentProgression,
	checkForContentAlreadyWatched
};

export default post;
