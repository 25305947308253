import _post from './post';
import _delete from './delete';
import _get from './get';

const VideoLikesManager = {
	_post,
	_delete,
	_get
};

export default VideoLikesManager;
