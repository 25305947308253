import React, { useContext, useEffect, lazy } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";

import {
  HomeScene,
  Notes,
  VideoSearchPage,
  VideosPage,
  WebinarDetails,
  WebinarPage,
  SpeakerProfilePage,
  LandingPage,
  PlayListPage,
  ReelsPage,
  EmployeeDashboard,
  EmployeeDashboardTeamPage,
  EmployeeDashboardAllDoctorPage,
  EmployeeDashboardEmployeePage,
  AcademyPage,
  AcademyDetails,
  NetraDhamaEvent,
  OurExpertPage,
  GetFeaturedPage
} from "./scenes";
import { UserContext } from "./contexts/UserContext/UserContextProvider";
import {
  EMPLOYEE_DASHBOARD_DOCTORS_ROUTE,
  EMPLOYEE_DASHBOARD_EMPLOYEE_ROUTE,
  EMPLOYEE_DASHBOARD_ROUTE,
  EMPLOYEE_DASHBOARD_TEAM_ROUTE,
  HOME_ROUTE,
  NOTES_ROUTE,
  PLAYLIST_PAGE_ROUTE,
  REELS_ROUTE,
  RootRoute,
  SEARCH_ROUTE,
  SERIES_DETAIL_ROUTE,
  SPEAKER_INFO_ROUTE,
  VIDEO_DETAIL_ROUTE,
  VIDEO_PAGE_ROUTE,
  WEBINAR_DETAIL_ROUTE,
  WEBINAR_PAGE_ROUTE,
  REELS_PAGE_ROUTE,
  ACADEMY_PAGE_ROUTE,
  ACADEMY_DETAIL_ROUTE,
  NETRA_DHARMA_EVENT_PAGE_ROUTE,
  OUR_EXPERTS_ROUTE,
  GET_FEATURED_ROUTE,
} from "./AppConstants/Routes";

import aa from "search-insights";
import { useTracking } from "./utils/useTracking";

const TermsOfUse = lazy(() => import("./components/Termsofuse/TermsOfUse"));
const DeleteUser = lazy(() => import("./components/Faq/DeleteUser"));
const VideoDetails = lazy(() => import("./components/VideoDetails"));
const SeriesDetails = lazy(() => import("./components/SeriesDetails"));
const AppLayout = lazy(() => import("./layouts/AppLayout"));
const NormalLayout = lazy(() => import("./layouts/NormalLayout"));
const Analytics = lazy(() => import("./components/Analytics"));

aa("init", {
  appId: "5DD1LQFKB3",
  apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_CLIENT_KEY,
});

aa("setUserToken", "3a3aOXfEcHgXTsbKUpb5B3zbg6G2");

const Router = () => {
  const { user } = useContext(UserContext);

  return (
    // <BrowserRouter>
    <ScrollToTop>
      {/* Add session context for login/signup and user data */}
      <CustomGAProvider>
        <Switch>
          <RouteWrapper
            // exact
            path={HOME_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={HomeScene}
          />
          <Route
            exact
            path={RootRoute}
            render={() => {
              if (user) {
                let returnURL = new URLSearchParams(window.location.search).get(
                  "returnURL"
                );

                if (returnURL) {
                  let _returnURL = decodeURIComponent(returnURL);

                  let pathName = _returnURL.split("?")[0];
                  let searchParams = _returnURL.split("?")[1];

                  return (
                    <Redirect
                      to={{
                        pathname: pathName ? pathName : "",
                        search: `?${searchParams ? searchParams : ""}`,
                      }}
                    />
                  ); //window.location.replace(returnURL);
                } else {
                  return (
                    <Redirect
                      to={{
                        pathname: HOME_ROUTE,
                        search: window?.location?.search,
                      }}
                    />
                  );
                }
              } else {
                return <LandingPage />;
              }
            }}
          />
          <RouteWrapper
            exact
            path={VIDEO_PAGE_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={VideosPage}
          />
          <RouteWrapper
            exact
            path={PLAYLIST_PAGE_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={PlayListPage}
          />
          <RouteWrapper
            exact
            path={SEARCH_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={VideoSearchPage}
          />
          <RouteWrapper
            exact
            path={SPEAKER_INFO_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={SpeakerProfilePage}
          />
          {/* <RouteWrapper
            exact
            path={REELS_PAGE_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={ReelsPage}
          />

          <RouteWrapper
            exact
            path={REELS_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={ReelsPage}
          /> */}
          <RouteWrapper
            exact
            path={EMPLOYEE_DASHBOARD_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={EmployeeDashboard}
          />
          <RouteWrapper
            exact
            path={EMPLOYEE_DASHBOARD_TEAM_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={EmployeeDashboardTeamPage}
          />
          <RouteWrapper
            exact
            path={EMPLOYEE_DASHBOARD_DOCTORS_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={EmployeeDashboardAllDoctorPage}
          />
          <RouteWrapper
            exact
            path={EMPLOYEE_DASHBOARD_EMPLOYEE_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={EmployeeDashboardEmployeePage}
          />

          <RouteWrapper
            exact
            path={VIDEO_DETAIL_ROUTE}
            // privateRoute={!user}
            layout={AppLayout}
            scene={VideoDetails}
          />
          <RouteWrapper
            exact
            path={SERIES_DETAIL_ROUTE}
            // privateRoute={!user}
            layout={AppLayout}
            scene={SeriesDetails}
          />

          <RouteWrapper
            exact
            path={NOTES_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={Notes}
          />
          <RouteWrapper
            exact
            path={WEBINAR_PAGE_ROUTE}
            // privateRoute={!user}
            layout={AppLayout}
            scene={WebinarPage}
          />
          <RouteWrapper
            exact
            path={WEBINAR_DETAIL_ROUTE}
            // privateRoute={!user}
            layout={AppLayout}
            scene={WebinarDetails}
          />
          <RouteWrapper
            exact
            path={ACADEMY_PAGE_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={AcademyPage}
          />
          <RouteWrapper
            exact
            path={ACADEMY_DETAIL_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={AcademyDetails}
          />

          <RouteWrapper
            exact
            path={OUR_EXPERTS_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={OurExpertPage}
          />

          {/* <RouteWrapper
            exact
            path={GET_FEATURED_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={GetFeaturedPage}
          /> */}


          {/* This can be wrapper under videos kept outside for guest/private view */}
          {/* <RouteWrapper
            // exact
            path="/video/:videoId?season=:seasonId#:tabId"
            layout={AppLayout}
            scene={VideoDetails}
          />
          <RouteWrapper
            // exact
            path="/series/:seriesId?season=:seasonId#:tabId"
            layout={AppLayout}
            scene={VideoDetails}
          /> */}
          {/* <RouteWrapper
            // exact
            path="/analytics/:webinarID"
            privateRoute={!user}
            layout={AppLayout}
            scene={Analytics}
          /> */}

          <RouteWrapper
            exact
            path={NETRA_DHARMA_EVENT_PAGE_ROUTE}
            // privateRoute={!user}
            layout={NormalLayout}
            scene={NetraDhamaEvent}
          />
          <RouteWrapper
            exact
            path={"/terms-of-use"}
            // privateRoute={!user}
            layout={NormalLayout}
            scene={TermsOfUse}
          />
          <RouteWrapper
            exact
            path={"/faq/deleteuser"}
            // privateRoute={!user}
            layout={NormalLayout}
            scene={DeleteUser}
          />
          <Route
            path={"*"}
            render={() => {
              if (user) {
                if (
                  window.location.pathname.includes("series") ||
                  window.location.pathname.includes("video")
                ) {
                  return (
                    <Redirect
                      to={{
                        pathname: VideosPage,
                        search: window?.location?.search,
                      }}
                    />
                  );
                } else {
                  return (
                    <Redirect
                      to={{
                        pathname: HOME_ROUTE,
                        search: window?.location?.search,
                      }}
                    />
                  );
                }
              } else {
                return <LandingPage />;
              }
            }}
          />
        </Switch>
      </CustomGAProvider>
    </ScrollToTop>
    // </BrowserRouter>
  );
};

const CustomGAProvider = (props) => {
  // #region
  // fire GA for every location change
  useTracking("UA-224057686-1");
  // useConditionalTracking("UA-228483250-1", "/thankyou", "googleads");
  //#endregion

  return <>{props.children}</>;
};

const RouteWrapper = ({
  scene: Scene,
  layout: Layout,
  privateRoute,
  ...rest
}) => {
  const { pathname, search = "", hash = "" } = useLocation();

  useEffect(() => {
    if (
      rest.path.includes(SERIES_DETAIL_ROUTE) ||
      rest.path.includes(VIDEO_DETAIL_ROUTE) ||
      rest.path.includes(WEBINAR_DETAIL_ROUTE) ||
      rest.path.includes(ACADEMY_DETAIL_ROUTE)
    ) {
    } else {
      document.title = "Docflix – OTT platform for doctors";
    }
  }, [rest]);

  let _search = search.split("?")[1];

  let _returnUrl =
    pathname && _search !== "undefined"
      ? `returnURL=${encodeURIComponent(pathname + search)}`
      : pathname
      ? `returnURL=${encodeURIComponent(pathname)}`
      : _search !== "undefined"
      ? `returnURL=${encodeURIComponent(search)}`
      : "";

  return (
    <Route
      {...rest}
      render={(props) => {
        return privateRoute ? (
          <Redirect to={`${RootRoute}?${_returnUrl}`} />
        ) : (
          <Layout {...props}>
            <Scene {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Router;
