//Imports
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	TRANSCIPT_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { SourceType, VideoType, LikeType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const updateTranscript = async (transcriptId, content, type = 'video') => {
	try {
		const transcriptRef = firestore.collection(TRANSCIPT_COLLECTION).doc(transcriptId);

		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(transcriptRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No TranscriptId Found'
				};
				throw err;
			}

			//Create transript
			transcation.update(transcriptRef, {
				type,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
				content
			});

			return;
		});
		return transcriptId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const put = { updateTranscript };

export default put;
