import { AGENDA_COLLECTION } from '../../../AppConstants/CollectionConstants';
import firebase, { firestore } from '../../../firebase/firebase';

const uniqid = require('uniqid');

const createAgenda = async ({ title, description, time, speakers, webinarId }) => {
	try {
		const agendaId = uniqid('agenda-');

		let agenda = {
			title,
			webinarId,
			description,
			time,
			speakers,
			id: agendaId,
			likes: 0,
			rating: 0,
			ratingsgiven: 0,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		await firestore.runTransaction(async (transaction) => {
			transaction.set(firestore.collection(AGENDA_COLLECTION).doc(agendaId), agenda);
		});

		return agendaId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createAgenda
};

export default post;
