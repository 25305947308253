import { LIKES_COLLECTION, DISLIKES_COLLECTION } from '../../../AppConstants/CollectionConstants';
import firebase, { firestore } from '../../../firebase/firebase';

// const getLikes = async (userId, dislike = false) => {
// 	try {
// 		const LikeRef = firestore
// 			.collection(dislike ? DISLIKES_COLLECTION : LIKES_COLLECTION)
// 			.where('user', '==', userId);
// 		const response = await LikeRef.get();
// 		let res_arr = [];
// 		if (!response.empty) {
// 			res_arr = response.docs.map((doc) => doc.data().targetId);
// 		}
// 		return res_arr;
// 	} catch (error) {
// 		console.log('Error :: ', error);
// 		throw error;
// 	}
// };

const getLikes = async (userId) => {
	try {
		const LikeRef = firestore
			.collection(LIKES_COLLECTION)
			.where('user', '==', userId);
		const response = await LikeRef.get();
		let res_arr = {};
		if (!response.empty) {
			response.docs.forEach((doc) => {
				let d = doc.data()
				res_arr[d.targetId] = d
			});
		}
		return res_arr;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const _get = {
	getLikes
};

export default _get;
