import { RESOURCES_COLLECTION } from '../../AppConstants/CollectionConstants';
// import { ResouceType } from '../../AppConstants/TypeConstants';

import firebase, { firestore } from '../../firebase/firebase';

const getResourceById = async (resourceId) => {
	try {
		const docRef = firestore.collection(RESOURCES_COLLECTION).doc(resourceId);
		const doc = await docRef.get();
		if (!doc.exists) {
			let err = {
				code: 'NotValidId',
				message: 'No resources found'
			};
			throw err;
		}

		return doc.data();
		// const _data = await firestore.runTransaction(async (transcation) => {
		// 	let doc = await transcation.get(docRef);
		// 	if (!doc.exists) {
		// 		let err = {
		// 			code: 'NotValidId',
		// 			message: 'No resources found'
		// 		};
		// 		throw err;
		// 	}

		// 	return doc.data();
		// });
		// return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const get = {
	getResourceById
};

export default get;
