/**
 * 
 * TODO : Figure out a way to Pass Firebase Timestamp to Clevertap Event.
 */

export const ArrayJoin = (arr: Array<string>): string => {
    return arr.map((el) => el.trim()).join(',');
}

export interface Global {
    'Page': string,
    'URL': string,
    'Section': string // Screen
}

export interface PageVisit {
    'Screen': string,
    'URL': string,
    'Platform': string // Screen
}

export interface UserGlobal {
    'User Profession': string,
    'User Speciality': string | null,
    'User Entered Location': {
        city: string | null,
        state: string | null,
        country: string | null
    }
}

export interface Base {
    'Content ID': string,
    'Content Title': string,
    'Content Description': string,
    'Content Type': "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    'Content Tags': string, //[]
    'Content Speciality': string,//[]
    'Content Therapy': string,//[]
    'Content Misc': string,//[]
    'Landscape Thumbnail': string | null,
    'Portrait Thumbnail': string | null,
}


export interface SignUp extends UserGlobal {
    "Name": string,
    "Email": string,
    "Phone": string,
    "Employee Code":string,
    'Campaign': any,
    'Medium': any,
    'Source': any,
    'Referrer': any,
    'Method Used': string, //Mode of login e.g. Social, email, phone etc
    'Status': boolean,
    'Status Message': string,
}

export interface Login {
    "Phone": string,
    'Method Used': string, //Mode of login e.g. Social, email, phone etc
    'Status': boolean,
    'Status Message': string,
}

export interface OTPVerify {
    'Status': boolean,
    'Medium': string, //Email OTP, TOTP, SMS OTP
    'Status Message': string,
}

export interface OTPSend {
    'Retries': string | Number,
    'Medium': string, //Email OTP, TOTP, SMS OTP
    'Status': boolean,
    'Status Message': string,
}


export interface FeedbackSubmit {
    "Platform": string
    "URL": string
    "Feedback Type": string
    "Message": string
    "Email": string
    "Phone": string
}


export interface Rating extends Base {
    "Rate": Number
}

export interface Speaker extends Base {
    "Speaker Id": string,
    "Speaker Name": string,
}


export interface Note extends Base {
    "Start Time": string,
    "End Time": string,
    "Note": string,
}

export interface Search {
    "Search Term": string,
    // "Filters": any, //[]Filters_
    'Status': boolean,
    'Status Message': string,
}

export interface SearchClick extends Base {
    "Search Term": string,
    // "Filters": any, //[] Filters_
    "Search Result Index": string, //[]
}


export interface Course extends Base {
    'Marks': number,
    'Private': boolean,
    'Closed Group': boolean,
}

export interface CourseChapter extends Base {
    'Course ID': string,
    'Course Title': string,
    'Marks': number
}

export interface CourseAssessment extends Base {
    'Course ID': string,
    'Course Title': string,
    'Passing Criteria': number,
    'Marks': number
}

export interface CourseAssessmentSubmit extends CourseAssessment {
    'Course ID': string,
    'Course Title': string,
    'Passing Criteria': number,
    'Marks': number,
    'Result': string,
    'Percentage': string
}

export interface Webinar extends Base {
    'Start Time': string,
    'End Time': string,
}

export interface WebinarRegister extends Webinar {
    'Status': boolean,
    'Status Message': string,
}

export interface Agenda extends Base {
    'Start Time': string,
    'Webinar ID': string,
    'Webinar Title': string,
}

export interface Episode extends Base {
    'Series ID': string,
    'Season ID': string,
    'Series Title': string,
    'Season Title': string,
    'Season Index': string | number,
    'Episode Index': string | number,
}

export interface Season extends Base {
    'Series ID': string,
    'Series Title': string,
    'Season Index': string | number,
}


export interface Subtab extends Base {
    "Section": string
}

export interface Share extends Base {
    "Medium": string
}

export interface LoyalityPoints {
    "Loyalty Points": number,
    "Type":string,
    "Timestamp":string,
}