import _get from './get';
import _post from './post';
import _put from './put';
import _delete from './delete';

const NotesManager = {
	_get,
	_post,
	_put,
	_delete
};

export default NotesManager;