//Imports
import {
	BACKSTAGE_COLLECTION,
	SEASON_COLLECTION,
	USER_MEDIA_METADATA_COLLECTION,
	VIDEO_COLLECTION
} from '../../AppConstants/CollectionConstants';
import { DESCENDING, TIMESTAMP } from '../../AppConstants/SortConstants';
import { VIDEO_FETCH_LIMIT } from '../../AppConstants/Constants';
import { VideoType, Video_States } from '../../AppConstants/TypeConstants';

// Firebase
import { firestore, database } from '../../firebase/firebase';

const getVideos = async (limit = VIDEO_FETCH_LIMIT) => {
	try {
		let docRef = firestore.collection(VIDEO_COLLECTION).limit(limit);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});

		return _data;
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const getVideoById = async (videoId) => {
	try {
		const ref = await firestore.collection(VIDEO_COLLECTION).doc(videoId).get();
		if (!ref.exists) {
			const error = {
				code: 'NotValidId',
				message: 'No VideoId Found'
			};
			throw error;
		}

		if (ref.data().status === Video_States.READY) {
			return ref.data();
		} else {
			throw new Error('Video Not ready');
		}
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const getEpisodesBySeason = async (seasonId) => {
	try {
		if (!seasonId) {
			return [];
		}
		let res = await firestore
			.collection(VIDEO_COLLECTION)
			.where('videoData.seasonId', '==', seasonId)
			.where('videoType', '==', VideoType.VIDEO_SERIES)
			.where('status', '==', Video_States.READY)
			.get();

		if (res.empty) {
			return [];
		}
		let _data = [];
		res.docs.forEach((d) => {
			_data.push(d.data());
		});
		let sortedArray = _data.sort((a, b) => a.videoData.episodeId - b.videoData.episodeId);

		return sortedArray;
	} catch (error) {
		console.error('Error :: ', error);
		throw new Error(error);
	}
};

const getTrailersBySeason = async (seasonId) => {
	try {
		let res = await firestore
			.collection(VIDEO_COLLECTION)
			.where('videoData.seasonId', '==', seasonId)
			.where('videoType', '==', VideoType.TRAILER)
			.where('status', '==', Video_States.READY)
			.get();

		if (res.empty) {
			return [];
		}
		let _data = [];
		res.docs.forEach((d) => {
			_data.push(d.data());
		});
		let sortedArray = _data.sort((a, b) => a.videoData.trailerId - b.videoData.trailerId);

		return sortedArray;
	} catch (error) {
		console.error('Error :: ', error);
		throw new Error(error);
	}
};

const getTeasersBySeason = async (seasonId) => {
	try {
		let res = await firestore
			.collection(VIDEO_COLLECTION)
			.where('videoData.seasonId', '==', seasonId)
			.where('videoType', '==', VideoType.TEASER)
			.where('status', '==', Video_States.READY)
			.get();

		if (res.empty) {
			return [];
		}
		let _data = [];
		res.docs.forEach((d) => {
			_data.push(d.data());
		});
		let sortedArray = _data.sort((a, b) => a.videoData.trailerId - b.videoData.trailerId);

		return sortedArray;
	} catch (error) {
		console.error('Error :: ', error);
		throw new Error(error);
	}
};

const getSortedVideos = async ({
	tags = [],
	based_on = TIMESTAMP,
	order = DESCENDING,
	lastDocRef = null,
	limit = VIDEO_FETCH_LIMIT,
	ignoreLimit = false,
	speciality = null
}) => {
	try {
		let docRef = firestore.collection(VIDEO_COLLECTION);
		if (tags && tags.length && speciality) {
			speciality = speciality.toLowerCase();
			tags = tags.map((t) => `${t.toLowerCase()}${speciality}`);

			docRef = docRef.where('tag_speciality', 'array-contains-any', tags);
		} else if (tags && tags.length) {
			tags = tags.map((t) => t.toLowerCase()); // tags to lowercase
			docRef = docRef.where('tags', 'array-contains-any', tags);
		}
		docRef = docRef.where('status', '==', Video_States.READY);

		docRef = docRef.orderBy(based_on, order);

		if (!ignoreLimit) docRef = docRef.limit(limit);

		// Pagination
		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});
		return { data: _data, lastDocRef };
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const getVideosBySpeakerId = async (
	speakers = [],
	based_on = TIMESTAMP,
	order = DESCENDING,
	lastDocRef = null,
	limit = VIDEO_FETCH_LIMIT,
	ignoreLimit = false
) => {
	try {
		let docRef = firestore.collection(VIDEO_COLLECTION);
		if (speakers && speakers.length) {
			docRef = docRef.where('speakers', 'array-contains-any', speakers);
		}
		docRef = docRef.where('status', '==', Video_States.READY);

		docRef = docRef.orderBy(based_on, order);

		if (!ignoreLimit) docRef = docRef.limit(limit);

		// Pagination
		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});
		return { data: _data, lastDocRef };
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const fetchMostViewedVideos = async ({ lastDocRef = '0', limit = 6, ignoreLimit = false }) => {
	try {
		let allVideosIds_array = [];
		let _lastDocRef = lastDocRef;
		const dbRef = database.ref();
		let dbRes = await dbRef
			.child('backstage')
			.child('MostWatched')
			.orderByKey()
			.limitToFirst(!ignoreLimit ? limit : '')
			.startAt(_lastDocRef)
			.once('value');

		if (!dbRes.exists()) {
			return [];
		}

		dbRes.forEach((childSnapshot) => {
			allVideosIds_array.push(childSnapshot.val());
		});

		const data = await Promise.allSettled(allVideosIds_array.map((videoId) => getVideoById(videoId)));
		let _data = data.map((doc) => doc.status === 'fulfilled' && doc.value);
		_lastDocRef = _data.length.toString();

		return { data: _data, lastDocRef: _lastDocRef };
	} catch (error) {
		console.error(error);
		throw error;
	}
};

const fetchVideoByUserSpeciality = async ({
	speciality,
	lastDocRef = null,
	limit = VIDEO_FETCH_LIMIT,
	ignoreLimit = false
}) => {
	try {
		if (!speciality) {
			return [];
		}
		let docRef = firestore
			.collection(VIDEO_COLLECTION)
			.where('speciality', 'array-contains', speciality.toLowerCase());

		if (!ignoreLimit) docRef = docRef.limit(limit);

		// Pagination
		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});
		return { data: _data, lastDocRef };
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const fetchYouMayAlsoLike = async (uid) => {
	try {
		let marketingRef = await firestore.collection('MarketingMetadata').doc(uid).get();
		if (!marketingRef.exists) {
			return [];
		}

		let marketingData = marketingRef.data();
		if (!marketingData['VideoTagViewed'] || !marketingData['VideoTagViewed'].length) {
			return [];
		}

		let completedVideos = [];
		if (marketingData['lastCompleteContent'] && marketingData['lastCompleteContent'].length) {
			marketingData['lastCompleteContent'].forEach((doc) => {
				if (doc.id) {
					completedVideos.push(doc.id);
				}
			});
		}
		let uncompleteVideos = [];
		if (marketingData['lastUncompleteContent'] && marketingData['lastCompleteContent'].length) {
			marketingData['lastUncompleteContent'].forEach((doc) => {
				if (doc.id) {
					uncompleteVideos.push(doc.id);
				}
			});
		}

		let lastWatchedVideoTags = [];
		if (marketingData['VideoTagViewed'] && marketingData['VideoTagViewed'].length) {
			if (marketingData['VideoTagViewed'].length >= 10) {
				let splicedArr = marketingData['VideoTagViewed'].splice(
					marketingData['VideoTagViewed'].length - 10,
					10
				);
				lastWatchedVideoTags.push(...splicedArr);
			} else {
				let splicedArr = marketingData['VideoTagViewed'].splice(0, 10);
				lastWatchedVideoTags.push(...splicedArr);
			}
		}

		// console.log(lastWatchedVideoTags, uncompleteVideos, completedVideos);

		let videoQuery = null;
		if (lastWatchedVideoTags.length) {
			videoQuery = firestore
				.collection(VIDEO_COLLECTION)
				.where('metaTags', 'array-contains-any', lastWatchedVideoTags);
		}
		videoQuery = videoQuery.where('status', '==', Video_States.READY).limit(10);

		let videoDocs = await videoQuery.get();
		videoDocs = videoDocs.docs.map((doc) => doc.data());
		if (videoDocs.length - completedVideos.length >= 6) {
			videoDocs = videoDocs.filter((doc) => {
				return completedVideos.includes(doc['id']) === false;
			});
		}
		return { data: videoDocs, lastDocRef: null };
	} catch (error) {
		console.error(error);
	}
};

// 1. For Doctor Pushpendra
/**
 * Add video tags to series and season and creation of an episode
 * Update existing episode's seasons and series
 * Fetch Seasons based on speciality in seasons
 */
const fetchSeasonsByUserSpeciality = async ({
	speciality,
	lastDocRef = null,
	limit = VIDEO_FETCH_LIMIT,
	ignoreLimit = false
}) => {
	try {
		if (!speciality) {
			return [];
		}
		let docRef = firestore
			.collection(SEASON_COLLECTION)
			.where('speciality', 'array-contains', speciality.toLowerCase());

		if (!ignoreLimit) docRef = docRef.limit(limit);

		// Pagination
		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});
		return { data: _data, lastDocRef };
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

// 2. Continue Watching
/**
 *  Fetch last 5 - 10 episodes from usermediametadata sort by updatetimestamp desc
 */
const continueWatching = async ({ uid, lastDocRef = null, limit = VIDEO_FETCH_LIMIT, ignoreLimit = false }) => {
	try {
		let docRef = firestore
			.collection(USER_MEDIA_METADATA_COLLECTION)
			.where('userId', '==', uid)
			.where('targetType', '==', 'series')
			.orderBy('updatedTimestamp', 'desc');

		if (!ignoreLimit) docRef = docRef.limit(limit);

		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data().seasonId);
		});
		_data = [ ...new Set(_data) ];

		let returnData = [];
		_data.forEach(async (seasonId) => {
			let seasonRef = await firestore.collection(SEASON_COLLECTION).doc(seasonId).get();
			if (seasonRef.exists && seasonRef.data().status === Video_States.READY) {
				returnData.push(seasonRef.data());
			}
		});

		return { data: returnData, lastDocRef };
	} catch (error) {
		console.error(error);
		throw error;
	}
};

// 3. Trending Shows
/**
 *
 */

// 4. Recently Added
/**
 * Based on timestamp - Show Seasons
 */
const recentlyAddedSeasons = async ({ lastDocRef = null, limit = VIDEO_FETCH_LIMIT, ignoreLimit = false }) => {
	try {
		let docRef = firestore
			.collection(SEASON_COLLECTION)
			.where('status', '==', Video_States.READY)
			.orderBy('timestamp', 'desc');
		if (!ignoreLimit) docRef = docRef.limit(limit);

		// Pagination
		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});
		return { data: _data, lastDocRef };
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

// 5. Show of the Month ( Manually, can be one or many, title can also be altered from backend )
const showOfTheMonth = async () => {
	try {
		const docRef = await firestore.collection(BACKSTAGE_COLLECTION).doc('showOfTheMonth').get();
		if (!docRef.exists) {
			return {
				data: []
			};
		}
		return docRef.data();
	} catch (error) {
		console.error(error);
		throw error;
	}
};

// 6. Most Viewed Shows - seasons
/**
 * Backstage - replace videos with seasons
 */
const fetchMostViewedSeasons = async () => {
	try {
		let docRef = await database.ref('backstageV2/MostWatchedSeasons').get();
		if (!docRef.exists()) {
			return [];
		} else {
			return {
				data: docRef.val(),
				lastDocRef: null
			};
			//   let seasonIds = docRef.val();

			//   let returnData = [];
			//   seasonIds.forEach(async (seasonId) => {
			//     let seasonRef = await firestore
			//       .collection(SEASON_COLLECTION)
			//       .doc(seasonId)
			//       .get();
			//     if (
			//       seasonRef.exists &&
			//       seasonRef.data().status === Video_States.READY
			//     ) {
			//       returnData.push(seasonRef.data());
			//     }
			//   });

			//   return { data: returnData, lastDocRef: null };
		}
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

// 7. Top 10 Shows - series
/**
 * Backstage- already done
 */

// 8. Latest Shows
/**
 * Need clarity
 */

const get = {
	getVideos,
	getVideoById,
	getEpisodesBySeason,
	getSortedVideos,
	getVideosBySpeakerId,
	getTrailersBySeason,
	getTeasersBySeason,
	fetchMostViewedVideos,
	fetchVideoByUserSpeciality,
	fetchYouMayAlsoLike,
	continueWatching,
	recentlyAddedSeasons,
	fetchMostViewedSeasons,
	fetchSeasonsByUserSpeciality,
	showOfTheMonth
};

export default get;
