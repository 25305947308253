import { AGENDA_COLLECTION, WEBINAR_COLLECTION } from '../../AppConstants/CollectionConstants';
import { WEBINAR_FETCH_LIMIT } from '../../AppConstants/Constants';
import { DESCENDING, TIMESTAMP } from '../../AppConstants/SortConstants';
import { firestore } from '../../firebase/firebase';

const getWebinar = async (webinarId) => {
	try {
		let docRef = firestore.collection(WEBINAR_COLLECTION).doc(webinarId);
		const doc = await docRef.get();
		if (!doc.exists) {
			const error = {
				code: 'NotValidId',
				message: 'No webinar found'
			};
			throw error;
		}

		return doc.data();
		// const _data = await firestore.runTransaction(async (transaction) => {
		// 	const doc = await transaction.get(docRef);
		// 	if (!doc.exists) {
		// 		const error = {
		// 			code: 'NotValidId',
		// 			message: 'No webinar found'
		// 		};
		// 		throw error;
		// 	}

		// 	let _data = doc.data();

		// 	return _data;
		// });

		// return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const fullWebinar = async (webinarId) => {
	try {
		let docRef = firestore.collection(WEBINAR_COLLECTION).doc(webinarId);
		const _data = await firestore.runTransaction(async (transaction) => {
			const doc = await transaction.get(docRef);
			if (!doc.exists) {
				const error = {
					code: 'NotValidId',
					message: 'No webinar found'
				};
				throw error;
			}

			let _data = doc.data();

			if (_data.agendas && _data.agendas.length) {
				let promiseArr = [];
				for (let id of _data.agendas) {
					promiseArr.push(transaction.get(firestore.collection(AGENDA_COLLECTION).doc(id)));
				}

				_data.agendaData = await Promise.all(promiseArr);
				_data.agendaData = _data.agendaData.map((doc) => doc.data());
			}

			return _data;
		});

		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getSortedWebinars = async (
	tags = [],
	based_on = TIMESTAMP,
	order = DESCENDING,
	lastDocRef = null,
	limit = WEBINAR_FETCH_LIMIT,
	ignoreLimit = false
) => {
	try {
		// console.log('WIP');
		let docRef = firestore.collection(WEBINAR_COLLECTION);
		if (tags && tags.length) {
			tags = tags.map((t) => t.toLowerCase());
			docRef = docRef.where('tags', 'array-contains-any', tags);
		}

		docRef = docRef.orderBy(based_on, order);
		if (!ignoreLimit) docRef = docRef.limit(limit);

		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});
		return { data: _data, lastDocRef };
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const get = {
	getSortedWebinars,
	getWebinar,
	fullWebinar
};

export default get;
