import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  ASSETS_BASE_URL,
  defaultRemoteConfig,
  defaultLottieData,
  ROW_TYPE_DATA,
} from "../../AppConstants/Constants";
import { TAGS_DATA } from "../../AppData/TagsData";
import { BackstageManager } from "../../Managers/Backstage";
import { UserContext } from "../UserContext/UserContextProvider";
import { BACKSTAGE_COLLECTION } from "../../AppConstants/CollectionConstants";
import { database, firestore, remoteConfig } from "../../firebase/firebase";
import { useLocation } from "react-router-dom";
import {
  EMPLOYEE_DASHBOARD_ROUTE,
  EMPLOYEE_DASHBOARD_TEAM_ROUTE,
} from "../../AppConstants/Routes";

export const ConstantDataContext = createContext();

const hotTopicsData = [
  {
    id: 1,
    title: "Cardio Unflip",
    season: "10",
    episode: "20",
    views: "1.2K",
    image: `${ASSETS_BASE_URL}/images/shows/cardioSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/cardioSmall.png`,
    videoID: "video-kyjvry5k",
    tags: ["Con-Verse", "Dyslipedmia", "IHD"],
  },
  {
    id: 2,
    title: "Cardio Unflip",
    season: "2",
    episode: "10",
    views: "2.2K",
    image: `${ASSETS_BASE_URL}/images/shows/digitalSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/digitalSmall.png`,
    videoID: "video-kyjvry5m",
    tags: ["Con-Verse", "Roadinar", "IHD"],
  },
  {
    id: 3,
    title: "Simplified",
    season: "3",
    episode: "30",
    views: "2K",
    image: `${ASSETS_BASE_URL}/images/shows/legendSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/legendSmall.png`,
    videoID: "video-kyjvry5o",
    tags: ["Con-Verse", "Diabetes", "IHD"],
  },
  {
    id: 4,
    title: "Out Of Office",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/shows/medicoSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/medicoSmall.png`,
    videoID: "video-kyjvry5q",
    tags: ["Con-Verse", "Roadinar", "IHD"],
  },
  {
    id: 5,
    title: "The Medico - Legal",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/shows/officeSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/officeSmall.png`,
    videoID: "video-kyjvry5q",
    tags: ["Con-Verse", "Diabetes", "IHD"],
  },
  {
    id: 6,
    title: "Unshaken",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/shows/simplifiedSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/simplifiedSmall.png`,
    videoID: "video-kyjvry5q",
    tags: ["Con-Verse", "Roadinar", "Diabetes"],
  },
  {
    id: 7,
    title: "The Guideline Zodiac",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/shows/stitchSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/stitchSmall.png`,
    videoID: "video-kyjvry5q",
    tags: ["Diabetes", "Roadinar", "IHD"],
  },
  {
    id: 8,
    title: "The Guideline Zodiac",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/shows/unshakenSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/unshakenSmall.png`,
    videoID: "video-kyjvry5q",
    tags: ["Diabetes", "Roadinar", "IHD"],
  },
  {
    id: 9,
    title: "The Guideline Zodiac",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/shows/vantageSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/vantageSmall.png`,
    videoID: "video-kyjvry5q",
    tags: ["Diabetes", "Roadinar", "IHD"],
  },
  {
    id: 10,
    title: "The Guideline Zodiac",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/shows/zodiacSmall.png`,
    imageMid: `${ASSETS_BASE_URL}/images/shows/zodiacSmall.png`,
    videoID: "video-kyjvry5q",
    tags: ["Diabetes", "Roadinar", "IHD"],
  },
];

const Speciality = [
  {
    id: 1,
    title: "Asthma",
    season: "10",
    episode: "20",
    views: "1.2K",
    image: `${ASSETS_BASE_URL}/images/speciality/Asthma.png`,
    imageMid: `${ASSETS_BASE_URL}/images/speciality/Asthma.png`,
    videoID: "video-kyjvry5k",
    tags: ["Con-Verse", "Dyslipedmia", "IHD"],
  },
  {
    id: 2,
    title: "Cardiology",
    season: "2",
    episode: "10",
    views: "2.2K",
    image: `${ASSETS_BASE_URL}/images/speciality/Cardiology.png`,
    imageMid: `${ASSETS_BASE_URL}/images/speciality/Cardiology.png`,
    videoID: "video-kyjvry5m",
    tags: ["Con-Verse", "Roadinar", "IHD"],
  },
  {
    id: 3,
    title: "COPD",
    season: "3",
    episode: "30",
    views: "2K",
    image: `${ASSETS_BASE_URL}/images/speciality/Copd.png`,
    imageMid: `${ASSETS_BASE_URL}/images/speciality/Copd.png`,
    videoID: "video-kyjvry5o",
    tags: ["Con-Verse", "Diabetes", "IHD"],
  },
  {
    id: 4,
    title: "Covid 19",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/speciality/Covid19.png`,
    imageMid: `${ASSETS_BASE_URL}/images/speciality/Covid19.png`,
    videoID: "video-kyjvry5q",
    tags: ["Con-Verse", "Roadinar", "IHD"],
  },
  {
    id: 5,
    title: "Pulmonology",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/speciality/Pulmonology.png`,
    imageMid: `${ASSETS_BASE_URL}/images/speciality/Pulmonology.png`,
    videoID: "video-kyjvry5q",
    tags: ["Con-Verse", "Diabetes", "IHD"],
  },
  {
    id: 6,
    title: "Radiology",
    season: "4",
    episode: "20",
    views: "3K",
    image: `${ASSETS_BASE_URL}/images/speciality/Radiology.png`,
    imageMid: `${ASSETS_BASE_URL}/images/speciality/Radiology.png`,
    videoID: "video-kyjvry5q",
    tags: ["Con-Verse", "Roadinar", "Diabetes"],
  },
];

const showModalInitialState = {
  visible: false,
  data: null,
  sectionId: null,
  sectionName: ""
};

const SpeakerPage_Default = {
  bestShows: [],
  speciality: [],
};

const defaultDocflixConstant = {
  videos: {
    skipBtnText: "Skip",
    outroBtnText: "Skip",
    skipBtnToggle: false,
    outroBtnToggle: false,

    latestTag: {
      show: false,
      text: "New",
    },
  },
};

export default function ConstantDataContextProvider(props) {
  const [TagsData, setTagsData] = useState({}); //TAGS_DATA
  const [showSearch, setShowSearch] = useState(false);
  const [showUserFeedback, setShowUserFeedback] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [selectedShow, setSelectedShow] = useState("");
  const [anouncementBar, setAnouncementBar] = useState({});
  const [speakerPage, setSpeakerPage] = useState(SpeakerPage_Default);
  const [featuredSectiondata, setFeaturedSectionData] = useState(null);
  const [seasonModalData, setSeasonModalData] = useState(showModalInitialState);
  const [allWatchLater, setAllWatchLater] = useState([]);
  const [isAnalyticsAvailable, setIsAnalyticsAvailable] = useState(false);
  const [privateSectiondata, setPrivateSectionData] = useState(null);
  const [globalDocflixConstant, setGlobalDocflixConstant] = useState(
    defaultDocflixConstant
  );
  const [remoteConfigData, setRemoteConfigData] = useState({
    ...defaultRemoteConfig,
    ...defaultLottieData,
  });

  const [selectedBreadCrumb, setSelectedBreadCrumb] = useState({
    tabs: ["Analytics Center"],
    activeTab: { tab: "Analytics Center" },
  });
  const { user, userBasicDetails } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    getRemoteConfig();
    getAnnouncementData();
  }, []);

  const getRemoteConfig = async () => {
    try {
      let fetchedRemotely = await remoteConfig.fetchAndActivate();

      let _remoteConfigs = remoteConfig.getAll();
      let _remoteConfigsData = {};

      Object.keys(_remoteConfigs).forEach((key) => {
        _remoteConfigsData[key] = JSON.parse(_remoteConfigs[key]._value);
      });

      setRemoteConfigData((prev) => ({ ...prev, ..._remoteConfigsData }));

      if (fetchedRemotely) {
        console.log("Configs were retrieved from the backend and activated.");
      } else {
        console.log(
          "No configs were fetched from the backend, and the local configs were already activated"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (location?.pathname) {
      let pathsArray = location?.pathname.split("/").filter((i) => i && i);
      let obj = {
        tabs: [],
        activeTab: { tab: "" },
      };

      for (let i = 0; i < pathsArray.length; i++) {
        switch (`/${pathsArray[i]}`) {
          case EMPLOYEE_DASHBOARD_ROUTE:
            obj["tabs"].push({
              tab: "Analytics Center",
              id: EMPLOYEE_DASHBOARD_ROUTE,
            });
            break;
          case "/teams":
            obj["tabs"].push({ tab: "Teams", id: "/teams" });
            break;
          case "/doctors":
            obj["tabs"].push({ tab: "Doctors", id: "/doctors" });
            break;
          case "/employee":
            obj["tabs"] = [
              ...obj["tabs"],
              { tab: "Teams", id: "/teams" },
              { tab: "Employee", id: "/employee" },
            ];
            break;
          case "/fieldAgent":
            obj["tabs"] = [
              ...obj["tabs"],
              { tab: "Teams", id: "/teams" },
              { tab: "Field Agent", id: "/fieldAgent" },
            ];
            break;
          default:
            break;
        }
      }
      let _activeTab = obj["tabs"][obj["tabs"].length - 1];
      obj["activeTab"] = _activeTab;
      updateEmployeeBreadCrumb(obj);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      BackstageManager.getTagsData()
        .then((_data) => {
          if (!_data) {
            // setTagsData(TagsData);
            return;
          }
          let _obj_data = {};
          _data.sort((a, b) => a.order - b.order);
          _data.forEach((element) => {
            _obj_data[element.sectionId] = element;
          });
          setTagsData(_obj_data);
        })
        .catch((err) => {
          console.error(err);
          setTagsData(TagsData);
        });

      BackstageManager.getSpeakerPageData()
        .then((_data) => {
          if (!_data) {
            setSpeakerPage(SpeakerPage_Default);
            return;
          }
          setSpeakerPage(_data);
        })
        .catch((err) => {
          console.error(err);
          setSpeakerPage(SpeakerPage_Default);
        });
    }
    BackstageManager.getDocflixConstant()
      .then((_data) => {
        if (!_data) {
          setGlobalDocflixConstant(defaultDocflixConstant);
          return;
        }
        setGlobalDocflixConstant(_data);
      })
      .catch((err) => {
        console.error(err);
        setGlobalDocflixConstant(defaultDocflixConstant);
      });
  }, [user]);

  useEffect(() => {
    if (userBasicDetails && userBasicDetails.phoneNumber) {
      checkAnalyticsAvailable(userBasicDetails.phoneNumber);
    }
  }, [userBasicDetails]);

  const handleSeasonModalClose = () => {
    setSeasonModalData(showModalInitialState);
  };

  const handleSeasonModalOpen = (sectionId, data) => {
    setSeasonModalData({
      visible: true,
      data: data,
      sectionId: sectionId,
      sectionName: ROW_TYPE_DATA[sectionId]?.heading || "",
    });
  };

  const getFeaturedSectionData = async () => {
    try {
      const docRef = await firestore
        .collection(BACKSTAGE_COLLECTION)
        .doc("featuredSection")
        .get();
      if (docRef.exists) {
        setFeaturedSectionData(docRef.data());
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getAnnouncementData = async () => {
    try {
      const docRef = await firestore
        .collection(BACKSTAGE_COLLECTION)
        .doc("anouncementBar")
        .get();
      if (docRef.exists) {
        let _d = docRef.data();
        if (_d?.enable) {
          let s = sessionStorage.getItem("announcementShow");
          if (!s) {
            setShowAnnouncement(true);
          }
        }
        setAnouncementBar(docRef.data());
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const checkAnalyticsAvailable = async (number) => {
    try {
      let unlocked = false;

      let docRef = await database.ref(`FieldAgentsDev/${number}`).once("value");

      if (docRef.exists()) {
        unlocked = true;
      }

      setIsAnalyticsAvailable(unlocked);
    } catch (error) {
      setIsAnalyticsAvailable(false);
      console.error("Error :: ", error);
    }
  };

  const getPrivateSectionData = async () => {
    try {
      const docRef = await firestore
        .collection(BACKSTAGE_COLLECTION)
        .doc("privateVideoSection")
        .get();
      if (docRef.exists) {
        setPrivateSectionData(docRef.data());
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateEmployeeBreadCrumb = (data) => {
    setSelectedBreadCrumb((prev) => {
      return { ...prev, ...data };
    });
  };

  const handleOpenUserFeedbackModal = useCallback(() => {
    setShowUserFeedback(true);
  }, []);
  const handleCloseUserFeedbackModal = useCallback(() => {
    setShowUserFeedback(false);
  }, []);
  return (
    <ConstantDataContext.Provider
      value={{
        TagsData,
        speakerPage,
        remoteConfigData,
        anouncementBar,
        globalDocflixConstant,
        seasonModalData,
        handleSeasonModalClose,
        handleSeasonModalOpen,
        featuredSectiondata,
        getFeaturedSectionData,
        showSearch,
        setShowSearch,
        selectedShow,
        setSelectedShow,
        allWatchLater,
        setAllWatchLater,
        getPrivateSectionData,
        privateSectiondata,
        updateEmployeeBreadCrumb,
        selectedBreadCrumb,
        isAnalyticsAvailable,
        showAnnouncement,
        setShowAnnouncement,
        handleCloseUserFeedbackModal,
        handleOpenUserFeedbackModal,
        showUserFeedback,
      }}
    >
      {props.children}
    </ConstantDataContext.Provider>
  );
}
