import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();
  //#region
  useEffect(() => {
    let hash = location.hash;
    if (hash) {
      // let element = document.querySelector(hash);
      // if (element) {
      //   element.scrollIntoView({ block: "center", behavior: "smooth" });
      // }
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "auto",
        });
      }, 1)
    }
  }, [location]);
  //#endregion

  return <>{props.children}</>;
};
export default ScrollToTop;
