export const USER_API_ROUTES = {
    "USER_CREATE": "/v1/userlocation",
    "USER_UPDATE": "/v1/user/update",
    "USER_OTP_SEND": "/v1/otp/send",
    "USER_OTP_VERIFY": "/v1/otp/verify",
    "USER_WEBINAR_REGISTER": "/v1/webinar/register",
    "GET_USERS_IN_AREA": "/v1/webinar/services/networking",
}
export const GLOBAL_API_ROUTES = {
    "SEND_EMAIL": "/v1/share/mail",
    "GENERATE_TEMP_VIDEO_TOKEN": "/v1/assets/token/tempgenerate",
    "SUBMIT_COURSE_ASSESSMENT": "/v1/academy/assessment/submit",
    "GENERATE_COURSE_CERTIFICATE": "/v1/academy/certificate",
    "ADD_REACTION": "/v1/webinar/reaction/add",
    "POP_REACTION": "/v1/webinar/reaction/pop",
    "PRIVATE_CODE_VALIDATION": "/v1/privatecode",
    "SEARCH_FACETS": "/v1/search/facets",
    "SEARCH": "/v1/search",
}