export const VIDEO_FETCH_LIMIT = 10;
export const SEASON_FETCH_LIMIT = 10;
export const WEBINAR_FETCH_LIMIT = 15;
export const DIMENSIONS = ["operating_system", "region", "playback_id"];

export const PLATFORM = {
  ANDROID_APP: "android_app",
  IOS_APP: "ios_app",
  WEB_APP: "web_app",
};

export const instituteValues = {
  "Andhra Pradesh Medical Council": {
    smcId: "1",
    name: "Andhra Pradesh Medical Council",
  },
  "Arunachal Pradesh Medical Council": {
    smcId: "2",
    name: "Arunachal Pradesh Medical Council",
  },
  "Assam Medical Council": { smcId: "3", name: "Assam Medical Council" },
  "Bhopal Medical Council": { smcId: "28", name: "Bhopal Medical Council" },
  "Bihar Medical Council": { smcId: "4", name: "Bihar Medical Council" },
  "Bombay Medical Council": { smcId: "29", name: "Bombay Medical Council" },
  "Chandigarh Medical Council": {
    smcId: "30",
    name: "Chandigarh Medical Council",
  },
  "Chattisgarh Medical Council": {
    smcId: "5",
    name: "Chattisgarh Medical Council",
  },
  "Delhi Medical Council": { smcId: "6", name: "Delhi Medical Council" },
  "Goa Medical Council": { smcId: "7", name: "Goa Medical Council" },
  "Gujarat Medical Council": { smcId: "8", name: "Gujarat Medical Council" },
  "Haryana Medical Council": { smcId: "9", name: "Haryana Medical Council" },
  "Himachal Pradesh Medical Council": {
    smcId: "10",
    name: "Himachal Pradesh Medical Council",
  },
  "Hyderabad Medical Council": {
    smcId: "45",
    name: "Hyderabad Medical Council",
  },
  "Jammu & Kashmir Medical Council": {
    smcId: "11",
    name: "Jammu & Kashmir Medical Council",
  },
  "Jharkhand Medical Council": {
    smcId: "12",
    name: "Jharkhand Medical Council",
  },
  "Karnataka Medical Council": {
    smcId: "13",
    name: "Karnataka Medical Council",
  },
  "Madhya Pradesh Medical Council": {
    smcId: "15",
    name: "Madhya Pradesh Medical Council",
  },
  "Madras Medical Council": { smcId: "36", name: "Madras Medical Council" },
  "Mahakoshal Medical Council": {
    smcId: "35",
    name: "Mahakoshal Medical Council",
  },
  "Maharashtra Medical Council": {
    smcId: "16",
    name: "Maharashtra Medical Council",
  },
  "Manipur Medical Council": { smcId: "26", name: "Manipur Medical Council" },
  "Medical Council of India": { smcId: "46", name: "Medical Council of India" },
  "Medical Council of Tanganyika": {
    smcId: "47",
    name: "Medical Council of Tanganyika",
  },
  "Mizoram Medical Council": { smcId: "42", name: "Mizoram Medical Council" },
  "Mysore Medical Council": { smcId: "37", name: "Mysore Medical Council" },
  "Nagaland Medical Council": { smcId: "41", name: "Nagaland Medical Council" },
  "Orissa Council of Medical Registration": {
    smcId: "17",
    name: "Orissa Council of Medical Registration",
  },
  "Pondicherry Medical Council": {
    smcId: "38",
    name: "Pondicherry Medical Council",
  },
  "Punjab Medical Council": { smcId: "18", name: "Punjab Medical Council" },
  "Rajasthan Medical Council": {
    smcId: "19",
    name: "Rajasthan Medical Council",
  },
  "Sikkim Medical Council": { smcId: "20", name: "Sikkim Medical Council" },
  "Tamil Nadu Medical Council": {
    smcId: "21",
    name: "Tamil Nadu Medical Council",
  },
  "Telangana State Medical Council": {
    smcId: "43",
    name: "Telangana State Medical Council",
  },
  "Travancore Cochin Medical Council, Trivandrum": {
    smcId: "50",
    name: "Travancore Cochin Medical Council, Trivandrum",
  },
  "Tripura State Medical Council": {
    smcId: "22",
    name: "Tripura State Medical Council",
  },
  "Uttar Pradesh Medical Council": {
    smcId: "23",
    name: "Uttar Pradesh Medical Council",
  },
  "Uttarakhand Medical Council": {
    smcId: "24",
    name: "Uttarakhand Medical Council",
  },
  "Vidharba Medical Council": { smcId: "40", name: "Vidharba Medical Council" },
  "West Bengal Medical Council": {
    smcId: "25",
    name: "West Bengal Medical Council",
  },
};
export const years = [
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
  "1979",
  "1978",
  "1977",
  "1976",
  "1975",
  "1974",
  "1973",
  "1972",
  "1971",
  "1970",
  "1969",
  "1968",
  "1967",
  "1966",
  "1965",
  "1964",
  "1963",
  "1962",
  "1961",
  "1960",
  "1959",
  "1958",
  "1957",
  "1956",
  "1955",
  "1954",
  "1953",
  "1952",
  "1951",
  "1950",
  "1949",
  "1948",
  "1947",
  "1946",
  "1945",
  "1944",
  "1943",
  "1942",
  "1941",
  "1940",
  "1939",
  "1938",
  "1937",
  "1936",
  "1935",
  "1934",
  "1933",
  "1932",
  "1931",
];

export const whiteListCountries = ["IN", "NP"];
export const CITY_DEBOUNCE = 1000;
export const PLAYER_EVENT_DEBOUNCE_IN_SEC = 15;
export const CITY_FETCH_MIN_CHAR = 2;
export const VIDEO_SEARCH_CHAR_LIMIT = 2;
export const SEARCH_DEBOUNCE = 1500;
export const USER_INTERACTION_DEBOUNCE = 1000;
export const SITE_TIMER_IN_MINUTES = 10;
export const openRoutes = [
  "nethraDhama",
  "series",
  "video",
  "terms-of-use",
  "webinar",
];

export const SEARCH_HEADING = {
  series: { heading: "Series" },
  academy: { heading: "Academy" },
  video: { heading: "Videos" },
};

export const ASSETS_BASE_URL = `${process.env.REACT_APP_STORAGE_BASE_URL}docflix-app/assets`;

export const STORAGE_BASE_URL = process.env.REACT_APP_STORAGE_BASE_URL;
export const DYNAMIC_LINK_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_API_KEY}`

export const DOCFLIX_WEBINAR_BACKGROUND_IMAGE = `${STORAGE_BASE_URL}docflixwebinarbackground.jpg`;

export const BUNDLE_ID = "com.mankind.docflix";
export const iosAppStoreId = "1631729377";

export const ratingsArray = [
  { rate: 0.25, title: "0.25", landscapeTitle: "0.25x" },
  { rate: 0.5, title: "0.5", landscapeTitle: "0.5x" },
  { rate: 0.75, title: "0.75", landscapeTitle: "0.75x" },
  { rate: 1, title: "Normal", landscapeTitle: "1x" },
  { rate: 1.25, title: "1.25", landscapeTitle: "1.25x" },
  { rate: 1.5, title: "1.5", landscapeTitle: "1.5x" },
  { rate: 1.75, title: "1.75", landscapeTitle: "1.75x" },
  { rate: 2, title: "2", landscapeTitle: "2x" },
];

export const ROW_TYPE = {
  RECOMMENDED_ROW: "recommended_for_you",
  CONTINUE_WATCHING: "Continue_Watching",
  RECENTLY_ADDED: "RecentlyAdded",
  MOST_VIEWED_SHOWS: "mostViewedShows",
  LATEST_SHOWS: "latestShows",
  TRENDING_SHOWS: "trendingShows",
  HOT_TOPICS_CARD: "hotTopicsCard",
};
export const ROW_TYPE_DATA = {
  RECOMMENDED_ROW: { heading: "Recommended Row" },
  CONTINUE_WATCHING: { heading: "Continue Watching" },
  RECENTLY_ADDED: { heading: "Recently Added" },
  MOST_VIEWED_SHOWS: { heading: "Most Viewed Seasons" },
  LATEST_SHOWS: { heading: "Latest Shows" },
  TRENDING_SHOWS: { heading: "Trending Shows" },
  HOT_TOPICS_CARD: { heading: "Hot Topics" },
};

// default remote config accepts only number boolean and string
export const defaultLottieData = {
  christmasNav: require("../assets/lotties/festivals/christmas/christmasNav.json"),
  christmasSplashDesktop: require("../assets/lotties/festivals/christmas/christmasSplashDesktop.json"),
  christmasSplashMobile: require("../assets/lotties/festivals/christmas/chrsitmasSplashMobile.json"),
  newYearNav: require("../assets/lotties/festivals/newYear/newYearNav.json"),
  newYearSplashDesktop: require("../assets/lotties/festivals/newYear/newYearSplashDesktop.json"),
  newYearSplashMobile: require("../assets/lotties/festivals/newYear/newYearSplashMobile.json"),
  republicNav: require("../assets/lotties/festivals/republic/republicNav.json"),
  republicSplashMobile: require("../assets/lotties/festivals/republic/republicSplashMobile.json"),
  republicSplashDesktop: require("../assets/lotties/festivals/republic/republicSplashDesktop.json"),
  womenDayNav: require("../assets/lotties/festivals/womenDay/womenDayNav.json"),
  womenDaySplashMobile: require("../assets/lotties/festivals/womenDay/womenDaySplashMobile.json"),
  womenDaySplashDesktop: require("../assets/lotties/festivals/womenDay/womenDaySplashDesktop.json"),
  holiNav: require("../assets/lotties/festivals/holi/holiNav.json"),
  holiSplashMobile: require("../assets/lotties/festivals/holi/holiSplashMobile.json"),
  holiSplashDesktop: require("../assets/lotties/festivals/holi/holiSplashDesktop.json"),
  doctorsDayNav: require("../assets/lotties/festivals/doctorsDay/doctorsDayNav.json"),
  doctorsDaySplashMobile: require("../assets/lotties/festivals/doctorsDay/doctorsDaySplashMobile.json"),
  doctorsDaySplashDesktop: require("../assets/lotties/festivals/doctorsDay/doctorsDaySplashDesktop.json"),
  independanceDayNav: require("../assets/lotties/festivals/independanceDay/independanceDayNav.json"),
  independanceDaySplashMobile: require("../assets/lotties/festivals/independanceDay/independanceDayMobile.json"),
  independanceDaySplashDesktop: require("../assets/lotties/festivals/independanceDay/independanceDayDesktop.json"),
  diwaliNav: require("../assets/lotties/festivals/diwali/diwaliNav.json"),
  diwaliSplashMobile: require("../assets/lotties/festivals/diwali/diwaliMobile.json"),
  diwaliSplashDesktop: require("../assets/lotties/festivals/diwali/diwaliDesktop.json"),
};

export const defaultRemoteConfig = {
  assessment: {
    fail: {
      description:
        "Test unsuccessful. No problem! Reattempt to advance. We're here to help you succeed in your learning goals. Best of luck",
      heading: "RETRY NEEDED!!",
      scored: "You Scored:",
      ctaText: "Retry",
      resourcesText: "Find source image for assessment questions > >"
    },
    pass: {
      description: "You’ve cleared the test with flying colors !",
      heading: "CONGRATULATIONS!",
      scored: "You Scored:",
      ctaText: "Next Chapter",
      resourcesText: "Find source image for assessment questions > >"
    },
    isLastChapter: {
      description: "You have completed the course",
      heading: "CONGRATULATIONS!",
      scored: "You Scored:",
      ctaText: "",
      resourcesText: ""
    },
  },
  featuredDocflixForm: {
    enable: true,
    heading: "Be the part of<br/>docflix family",
    heroBG: `${process.env.REACT_APP_STORAGE_BASE_URL}docflix-app/assets/images/featuredDocflixForm/headerBG.jpg`,
    thankYouImg: `${process.env.REACT_APP_STORAGE_BASE_URL}docflix-app/assets/images/featuredDocflixForm/thankyou.jpg`,
    CTAImage: `${process.env.REACT_APP_STORAGE_BASE_URL}docflix-app/assets/images/featuredDocflixForm/CTAImg.png`,
    languages: ["English", "Hindi", "Marathi"],
    education: [
      "MBBS",
      "BDS",
      "BHMS",
      "BAMS",
      "BUMS",
      "BNYS",
      "MD",
      "MS",
      "MDS",
      "DM",
      "MCh",
      "PhD in Medicine",
      "DNB",
      "PG Diploma",
      "Fellowship Programs",
      "Board Certification – Recognition in specialized fields after rigorous examination (like MRCP, FRCS, etc.)"
    ],
    specializations: [
      "Cardiology",
      "Dermatology",
      "Neurology",
      "Oncology",
      "Pediatrics",
      "Orthopedics",
      "Gastroenterology",
      "Gynecology and Obstetrics",
      "Endocrinology",
      "Ophthalmology",
      "ENT (Otorhinolaryngology)",
      "Nephrology",
      "Pulmonology",
      "Psychiatry",
      "Radiology",
      "Rheumatology",
      "Urology",
      "Anesthesiology",
      "General Surgery",
      "Plastic Surgery",
      "Hematology",
      "Immunology",
      "Pathology",
      "Family Medicine",
      "Sports Medicine",
      "Infectious Disease",
      "Geriatrics",
      "Neonatology",
      "Forensic Medicine",
      "Vascular Surgery"
    ]
  },
  festivalData: {
    activeSeason: "",
    christmas: {
      navLogo: "christmasNav",
      splashMobile: "christmasSplashMobile",
      splashDesktop: "christmasSplashDesktop",
      title: "Christmas",
    },
    newYear: {
      navLogo: "newYearNav",
      splashMobile: "newYearSplashMobile",
      splashDesktop: "newYearSplashDesktop",
      title: "New Year",
    },
    republic: {
      navLogo: "republicNav",
      splashMobile: "republicSplashMobile",
      splashDesktop: "republicSplashDesktop",
      title: "Republic Day",
    },
    womenDay: {
      navLogo: "womenDayNav",
      splashMobile: "womenDaySplashMobile",
      splashDesktop: "womenDaySplashDesktop",
      title: "Women's Day",
    },
    holi: {
      navLogo: "holiNav",
      splashMobile: "holiSplashMobile",
      splashDesktop: "holiSplashDesktop",
      title: "Happy Holi",
    },
    doctorsDay: {
      navLogo: "doctorsDayNav",
      splashMobile: "doctorsDaySplashMobile",
      splashDesktop: "doctorsDaySplashDesktop",
      title: "Happy Doctor's Day",
    },
    independanceDay: {
      navLogo: "independanceDayNav",
      splashMobile: "independanceDaySplashMobile",
      splashDesktop: "independanceDaySplashDesktop",
      title: "Happy Independence's Day",
    },
    diwali: {
      navLogo: "diwaliNav",
      splashMobile: "diwaliSplashMobile",
      splashDesktop: "diwaliSplashDesktop",
      title: "Happy Diwali",
    },
  },
  showFestivalAnimation: false,
};

export const eventSectionId = "EventRow";
