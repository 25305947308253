export const SIGN_UP_EVENT = "SIGNUP";
export const LOGIN_EVENT = "LOGIN";
export const OTP_SEND_EVENT = "OTP SEND";
export const OTP_RESEND_EVENT = "OTP RESEND";
export const OTP_VERIFY_EVENT = "OTP VERIFY";
export const FEEDBACK_SUBMIT_EVENT = "FEEDBACK SUBMIT";
export const RATING_BUTTON_EVENT = "RATING";
export const LIKE_BUTTON_EVENT = "LIKE";
export const DISLIKE_BUTTON_EVENT = "DISLIKE";
export const SPEAKER_CLICK_EVENT = "SPEAKER CLICK";
export const NOTE_CREATED_EVENT = "NOTE CREATED";
export const TAB_CLICK_EVENT = "TAB CLICK";
export const TAG_CLICK_EVENT = "TAG CLICK";
export const SPECIALITY_DROPDOWN_EVENT = "SPECIALITY DROPDOWN";
export const SEARCH_BEST_SHOWS_EVENT = "SEARCH BEST SHOWS CLICK";
export const SEARCH_TERM_EVENT = "SEARCH TERM";
export const SEARCH_RESULT_EVENT = "SEARCH RESULT SELECTED";
export const SEASON_SELECT_EVENT = "SEASON SELECT";
export const EPISODE_CLICK_EVENT = "EPISODE CLICK";
export const VIDEO_STANDALONE_CLICK_EVENT = "VIDEO STANDALONE CLICK";
export const COURSE_CLICK_EVENT = "COURSE CLICK";
export const COURSE_ENROLLED_EVENT = "COURSE ENROLLED";
export const CHAPTER_CLICK_EVENT = "CHAPTER CLICK";
export const ASSESSMENT_CLICK_EVENT = "ASSESSMENT CLICK";
export const ASSESSMENT_SUBMITTED_EVENT = "ASSESSMENT SUBMIT";
export const HOME_PAGE_CLICK_EVENT = "HOME PAGE CLICK";
export const SPEAKER_PAGE_CLICK_EVENT = "SPEAKER PAGE CLICK";
export const VIDEO_PAGE_CLICK_EVENT = "VIDEO PAGE CLICK";
export const WEBINAR_PAGE_CLICK_EVENT = "WEBINAR PAGE CLICK";
export const PAGE_VISITED_EVENT = "PAGE VISITED";
export const SUB_TAB_CLICK_EVENT = "SUB TAB CLICK";
export const WEBINAR_REGISTERATION_EVENT = "WEBINAR REGISTERATION CLICK";
export const WEBINAR_FEEDBACK_SUBMIT_EVENT = "WEBINAR FEEDBACK SUBMIT";
export const ADD_TO_WATCH_LATER_EVENT = "ADD TO WATCH LATER";
export const RESOURCE_DOWNLOAD_EVENT = "RESOURCE DOWNLOAD";
export const WEBINAR_JOINED_EVENT = "WEBINAR JOINED";
export const WEBINAR_COMPLETED_EVENT = "WEBINAR COMPLETED";
export const WEBINAR_QUITED_EVENT = "WEBINAR QUITED";
export const AGENDA_CHANGED_EVENT = "AGENDA CHANGED";
export const FEATURED_VIDEO_CLICK_EVENT = "FEATURED VIDEO";
export const POLL_SUBMIT_EVENT = "POLL SUBMIT";
export const QNA_SUBMIT_EVENT = "QNA SUBMIT";
export const FORUM_SUBMIT_EVENT = "FORUM SUBMIT";
export const LIVE_REACTION_EVENT = "LIVE REACTION";
export const SKIP_INTRO_CLICK_EVENT = "SKIP INTRO CLICK";
export const LOGOUT_CLICK_EVENT = "LOGOUT CLICK";
export const SHARE_CLICK_EVENT = "SHARE CLICK";
export const CAROUSAL_CLICK_EVENT = "CAROUSAL CLICK";
export const GET_CERTIFICATE_CLICK_EVENT = "GET CERTIFICATE CLICK";
export const FALLBACK_PAGE_EVENT = "FALLBACK PAGE";
export const GET_APP_EVENT = "GET APP";

//#region player events start
export const PLAYER_PLAY_EVENT = "PLAYER PLAY"
export const PLAYER_PAUSE_EVENT = "PLAYER PAUSE"
export const PLAYER_SEEK_EVENT = "PLAYER SEEK"
export const PLAYER_ENDED_EVENT = "PLAYER ENDED"
export const PLAYER_TIMEUPDATE_EVENT = "PLAYER TIMEUPDATE"
export const PLAYER_FULLSCREEN_EVENT = "PLAYER FULLSCREEN"
export const PLAYER_ERROR_EVENT = "PLAYER ERROR"
//#endregion player event end

export const UPDATE_LOYALITY_POINTS="UPDATE LOYALITY POINTS"
