//Imports
import {
	SPEAKERS_COLLECTION,
	SPEAKER_FOLLOWER_COLLECTION,
	USER_METADATA_COLLECTION
} from '../../../AppConstants/CollectionConstants';
// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const unFollow = async (speakerId, userId) => {
	try {
		const id = speakerId + '+' + userId;
		const docRef = firestore.collection(SPEAKER_FOLLOWER_COLLECTION).doc(id);
		const speakerRef = firestore.collection(SPEAKERS_COLLECTION).doc(speakerId);
		const userMetadataRef = firestore.collection(USER_METADATA_COLLECTION).doc('metadata-' + userId);

		await firestore.runTransaction(async (transcation) => {
			let _data = await transcation.get(docRef);
			let userMetadata = await transcation.get(userMetadataRef);
			if (!_data.exists) {
				let err = {
					code: 'NotValidId',
					message: 'Not found'
				};
				throw err;
			}

			if (!userMetadata.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No metadata found'
				};
				throw err;
			}

			transcation.delete(docRef);
			transcation.update(speakerRef, {
				followerCount: firebase.firestore.FieldValue.increment(-1),
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			});
			transcation.update(userMetadataRef, {
				speakers: firebase.firestore.FieldValue.arrayRemove(speakerId),
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			});
		});
		return 'Unfollowed';
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const _delete = {
	unFollow
};

export default _delete;
