import _get from './get';
import _post from './post';

import AgendaManager from './agenda';
import PollManager from './polls';
import QAManager from './qa';
import userFeedbackManager from './userFeedback'

const WebinarManager = {
	_get,
	_post,
	AgendaManager,
	PollManager,
	QAManager,
	userFeedbackManager
};

export default WebinarManager;
