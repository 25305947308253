import { createContext, useContext, useEffect, useReducer } from "react";
import AvatarManager from "../../Managers/Users/Avatar";
import {
  avatarCustomisationOptions,
  avatarObj,
  avatarOptions,
} from "../../scenes/DigitalAvatar/partials/ProfileScreen/avatarCustomisationData";
import { UserContext } from "../UserContext/UserContextProvider";

export const AvatarContext = createContext();

const initialState = {
  screen: "",
  key: "",
  avatar_settings: "",
  errors: false,
  loading: false,
  gender: "female",
};

const Action_Type = {
  handleScreenChange: "handleScreenChange",
  handleScreenReset: "handleScreenReset",
  handleCustomiseAvatar: "handleCustomiseAvatar",
  handleSaveDetails: "handleSaveDetails",
  handleAddOptions: "handleAddOptions",
  handleGenderChange: "handleGenderChange",
  handleSetLoading: "handleSetLoading",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.handleSetLoading:
      return {
        ...state,
        ...payload,
      };
    case Action_Type.handleScreenChange:
      return {
        ...state,
        ...payload,
      };
    case Action_Type.handleSaveDetails:
      return {
        ...state,
        ...payload,
      };
    case Action_Type.handleGenderChange:
      return {
        ...initialState,
        screen: "showAvatarScreen",
        cam_image: state.cam_image,
        key: state.key,
        ...payload,
      };
    case Action_Type.handleCustomiseAvatar:
      return {
        ...state,
        ...payload,
      };
    case Action_Type.handleAddOptions:
      let _state = { ...state };
      let id = Object.keys(payload);

      if (_state.hasOwnProperty(id[0])) {
        delete _state[id[0]];
        return {
          ..._state,
        };
      } else {
        return {
          ...state,
          ...payload,
        };
      }
    case Action_Type.reset:
      return {
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default function AvatarContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { user } = useContext(UserContext);

  const handleInitialConfiguration = (userBasicDetails, key, gender) => {
    let avatarCustomisationOptions = Object.values(
      avatarOptions[gender ? gender : state.gender]
    );
    let obj = {};
    let _obj = {};
    if (
      (key === "initial" &&
        userBasicDetails &&
        userBasicDetails.avatar &&
        userBasicDetails.avatar.key === "Avatar") ||
      (key === "update" && userBasicDetails !== null)
    ) {
      if (!gender) {
        dispatch({
          type: Action_Type.handleCustomiseAvatar,
          payload: { gender: userBasicDetails.avatar.gender },
        });
      }
      let userAvatar =
        key === "update"
          ? userBasicDetails
          : userBasicDetails.avatar.avatar_settings;
      Object.values(
        avatarObj[gender ? gender : userBasicDetails.avatar.gender]
      ).map((avatar) => {
        if (userAvatar.hasOwnProperty(avatar.id)) {
          if (avatar.multiple) {
            userAvatar[avatar.id].map((item) => {
              _obj = {
                id: item.id,
                img: avatar.options[item.id].img,
                zIndex: avatar.options[item.id].index,
                active: avatar.options[item.id].id,
              };
              obj[item.id] = _obj;
            });
          } else {
            let activeOption = userAvatar[avatar.id];
            _obj = {
              id: activeOption.option
                ? activeOption.option.id
                : activeOption.id,
              img: activeOption.option
                ? avatar.options[activeOption.id].options[
                    activeOption.option.id
                  ].img
                : avatar.options[activeOption.id].img,
              zIndex: avatar.options[activeOption.id].index,
              active: avatar.options[activeOption.id].id,
            };
            obj[avatar.id] = _obj;
          }
        }
      });
    } else {
      avatarCustomisationOptions.map((avatar) => {
        let _obj = {
          id: avatar.options[0].options
            ? avatar.options[0].options[0].id
            : avatar.options[0].id,
          img: avatar.options[0].options
            ? avatar.options[0].options[0].img
            : avatar.options[0].img,
          zIndex: avatar.options[0].options
            ? avatar.options[0].options[0].index
            : avatar.options[0].index,
          active: avatar.options[0].id,
        };

        if (avatar.multiple) {
          obj[avatar.options[0].id] = _obj;
        } else {
          obj[avatar.id] = _obj;
        }
      });
    }

    return dispatch({
      type: Action_Type.handleCustomiseAvatar,
      payload: obj,
    });
  };

  const handleScreen = (type, value) => {
    dispatch({
      type: Action_Type.handleScreenChange,
      payload: { [type]: value },
    });
  };

  const handleLoading = (value) => {
    dispatch({
      type: Action_Type.handleSetLoading,
      payload: { loading: value },
    });
  };

  const handleSave = async (value, image) => {
    let UserAvatarDetails = {};
    UserAvatarDetails["key"] = value;
    UserAvatarDetails["gender"] = state.gender;
    // UserAvatarDetails["avatar_settings"] = state.avatar_settings;
    if (image.id === "avatar_img") {
      UserAvatarDetails["avatar_full_img"] = image.full_image;
      UserAvatarDetails["avatar_face_img"] = image.half_img;
      UserAvatarDetails["avatar_settings"] = image.avatar_settings;
      UserAvatarDetails["cam_image"] = image.cam_image;
    } else {
      UserAvatarDetails["cam_image"] = image.img;
      UserAvatarDetails["avatar_settings"] = state.avatar_settings;
      handleLoading(false);
    }

    await AvatarManager._post.createAvatar(user.uid, UserAvatarDetails);
  };

  const addDetails = (type, value) => {
    dispatch({
      type: Action_Type.handleSaveDetails,
      payload: { [type]: value },
    });
  };

  const handleAvatar = (type, value) => {
    dispatch({
      type: Action_Type.handleCustomiseAvatar,
      payload: {
        [type.parentId]: {
          img: value.img,
          zIndex: value.index,
          id: value.id,
          active: type.id,
        },
      },
    });
  };

  const handleGenderChange = (value, settings) => {
    dispatch({
      type: Action_Type.handleGenderChange,
      payload: { gender: value },
    });
    handleInitialConfiguration(settings, "update", value);
  };

  const handleUpdateAvatar = (type, multiple) => {
    if (!multiple) {
      dispatch({
        type: Action_Type.handleCustomiseAvatar,
        payload: {
          [type.parentId]: {
            img: type.img,
            zIndex: type.index,
            id: type.id,
            active: type.id,
          },
        },
      });
    } else {
      dispatch({
        type: Action_Type.handleAddOptions,
        payload: {
          [type.id]: {
            img: type.img,
            zIndex: type.index,
            id: type.id,
            active: type.id,
          },
        },
      });
    }
  };

  return (
    <AvatarContext.Provider
      value={{
        handleScreen,
        handleUpdateAvatar,
        handleAvatar,
        handleInitialConfiguration,
        handleGenderChange,
        addDetails,
        handleSave,
        handleLoading,
        state,
      }}
    >
      {props.children}
    </AvatarContext.Provider>
  );
}
