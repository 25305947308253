//Imports
import { USER_COLLECTION, USER_METADATA_COLLECTION } from '../../AppConstants/CollectionConstants';

// Firebase
import firebase, { firestore } from '../../firebase/firebase';
const uniqid = require('uniqid');

const createUser = async ({
	fullName,
	phoneNumber,
	email,
	profession,
	speciality,
	country,
	state,
	city,
	pincode,
	MCI_Number,
	registrationSource
}) => {
	try {
		const UserId = uniqid('user-');
		const UserMetaId = 'metadata-' + UserId;

		// Setup User Document
		let user = {
			id: UserId,
			fullName,
			phoneNumber,
			email,
			profession,
			speciality,
			country,
			state,
			city,
			pincode,
			MCI_Number,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		await firestore.runTransaction(async (transcation) => {
			transcation.set(firestore.collection(USER_COLLECTION).doc(UserId), user);

			// creating metadata here and now
			transcation.set(firestore.collection(USER_METADATA_COLLECTION).doc(UserMetaId), {
				id: UserMetaId,
				webinars: [],
				speakers: [],
				UserId,
				registrationSource,
				timestamp: firebase.firestore.FieldValue.serverTimestamp()
			});
		});

		return UserId;
	} catch (error) {
		console.log('Error:: User:: ', error);
		throw error;
	}
};

const post = {
	createUser
};

export default post;
