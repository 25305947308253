//Imports
import { USER_NOTES } from "../../../AppConstants/CollectionConstants";

// Firebase
import { firestore } from "../../../firebase/firebase";

const deleteNote = async ({ targetId, userId, index }) => {
  try {
    if (!userId && !targetId) {
      throw new Error("Please provide a userId and targetId");
    }

    const noteId = `${userId}_${targetId}`;
    const docRef = firestore.collection(USER_NOTES).doc(noteId);

    await firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(docRef);
      if (!doc.exists) {
        const err = {
          code: "NotValidId",
          message: "No Notes Found",
        };
        throw err;
      }

      const _data = doc.data();
      if (index >= _data.content.length) {
        const err = {
          code: "Not Valid Index",
          message: "Please provide valid index",
        };

        throw err;
      }
      _data.content.splice(index, 1);
      transaction.update(docRef, _data);
    });

    return noteId;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const _delete = {
  deleteNote,
};

export default _delete;
