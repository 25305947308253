export const CloudFunctionName = {
  USER_EXISTS: "checkUserExists", //old function using
  REALTIME_METRICS: "getRealtimeWebinarMetrics", //old function using
  USER_TOKEN_UPDATE: "userTokenUpdate", //old function using
  ADD_NOTIFICATION_TO_REALTIME_DB: "addNotificationToRealTimeDB", //old function using
  UPDATE_NOTIFICATION_TO_REALTIME_DB: "updateNotificationToRealTimeDB", //old function using
  fetchEmployeeData: "fetchEmployeeData",
  fetchEmployeeAggregated: "fetchEmployeeAggregated",
  searchDoctor: "searchDoctor",
  fetchRegistrations: "fetchRegistrations",
  fetchTeamwiseAnalytics: "fetchTeamwiseAnalytics",
};
