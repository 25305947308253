import { RESOURCES_COLLECTION } from '../../AppConstants/CollectionConstants';
import { ResouceType } from '../../AppConstants/TypeConstants';

import firebase, { firestore } from '../../firebase/firebase';
const uniqid = require('uniqid');

const createResource = async ({ title, description, logoUrl, link, type, thumbnailUrl }) => {
	try {
		const resourceId = uniqid('resource-');
		const docRef = firestore.collection(RESOURCES_COLLECTION).doc(resourceId);
		const resource = {
			id: resourceId,
			link,
			title,
			description,
			thumbnailUrl,
			logoUrl,
			type: type ? type : ResouceType.Link,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		await firestore.runTransaction(async (transaction) => {
			transaction.set(docRef, resource);
		});
		return resourceId;
	} catch (error) {
		console.log('Error:: ', error);
		throw error;
	}
};

const post = {
	createResource
};

export default post;
