//Imports
import { USER_MEDIA_METADATA_COLLECTION } from '../../../AppConstants/CollectionConstants';
// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const createUserMediaMetadata = async ({
	userId,
	targetId,
	lastWatchTimestamp,
	totalDuration,
	targetType,
	targetData
}) => {
	try {
		const id = userId + targetId;
		const docRef = firestore.collection(USER_MEDIA_METADATA_COLLECTION).doc(id);

		await firestore.runTransaction(async (transaction) => {
			let doc = await transaction.get(docRef);
			if (!doc.exists) {
				// Setup Doc
				let _payload = {
					id: id,
					userId,
					targetId,
					lastWatchTimestamp,
					totalDuration,
					timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
					targetType,
					...targetData
				};
				transaction.set(docRef, _payload);
			} else {
				// Setup Doc
				let _payload = {
					lastWatchTimestamp,
					updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
				};
				transaction.update(docRef, _payload);
			}
		});

		return id;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createUserMediaMetadata
};

export default post;
