import { createContext, useContext, useEffect, useState } from "react";
import { ContentType } from "../../AppConstants/TypeConstants";
import VideoRatingManager from "../../Managers/Video/ratings";
import { UserContext } from "../UserContext/UserContextProvider";
import { AnalyticsContext } from "../Analytics/AnalyticsContextProvider";
import { RATING_BUTTON_EVENT } from "../../Clevertap/EventNames.ts";
import { getRatingData } from "../../Clevertap/EventHandler.ts";

export const RatingContext = createContext([]);

export default function RatingContextProvider(props) {
  const [data, setData] = useState({});
  const { user } = useContext(UserContext);
  const { addCleverTapAnalytics } = useContext(AnalyticsContext);

  useEffect(() => {
    if (!user) {
      return;
    }
    const getRating = async () => {
      let __data = await VideoRatingManager._get.getRating(user.uid);
      setData(__data);
    };
    getRating();
  }, []);

  const giveRating = async (content, targetType, targetId, rating) => {
    try {
      if (!targetType || !targetId) {
        return;
      }
      if (!user) {
        return;
      }

      addCleverTapAnalytics(RATING_BUTTON_EVENT, getRatingData({
        'targetType': targetType,
        'Rate': rating,
        ...content
      }))

      let _targetType =
        targetType === ContentType.SERIES || targetType === ContentType.TRAILER
          ? ContentType.VIDEO
          : targetType;

      // console.log(targetType, targetId);
      switch (_targetType) {
        case ContentType.VIDEO:
        case ContentType.CHAPTER:
          if (data.hasOwnProperty(targetId)) {
            await VideoRatingManager._put.updateRating(
              targetId,
              user.uid,
              rating,
              _targetType
            );
          } else {
            await VideoRatingManager._post.createRating(
              targetId,
              user.uid,
              rating,
              _targetType
            );
          }
          break;
        case ContentType.AGENDA:
          if (data.hasOwnProperty(targetId)) {
            await VideoRatingManager._put.updateRating(
              targetId,
              user.uid,
              rating,
              ContentType.AGENDA
            );
          } else {
            await VideoRatingManager._post.createRating(
              targetId,
              user.uid,
              rating,
              ContentType.AGENDA
            );
          }
          break;
        case ContentType.SEASON:
          if (data.hasOwnProperty(targetId)) {
            await VideoRatingManager._put.updateRating(
              targetId,
              user.uid,
              rating,
              ContentType.SEASON
            );
          } else {
            await VideoRatingManager._post.createRating(
              targetId,
              user.uid,
              rating,
              ContentType.SEASON
            );
          }
          break;
        default:
          console.error("No targetType passed");
          break;
      }
      setData((prev) => {
        let i = { ...prev };
        // console.log(`Target Rating :: `, i[targetId], rating);
        if (rating > 0) {
          i[targetId] = rating;
        } else if (i[targetId] && rating < 1) {
          delete i[targetId];
        }
        return i;
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RatingContext.Provider value={{ ratingData: data, giveRating }}>
      {props.children}
    </RatingContext.Provider>
  );
}
