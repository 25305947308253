import _post from './post';
import _put from './put';
import _get from './get';
import _delete from './delete';
const VideoRatingManager = {
	_post,
	_put,
	_get,
	_delete
};

export default VideoRatingManager;
