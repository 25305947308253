import avatar1 from "../../../../assets/avatar/1.png";
import avatarFemale1 from "../../../../assets/avatar/female/1.png";
import avatarFemale2 from "../../../../assets/avatar/female/2.png";
import avatarFemale3 from "../../../../assets/avatar/female/3.png";
import avatar2 from "../../../../assets/avatar/2.png";
import avatar3 from "../../../../assets/avatar/3.png";
import hair1 from "../../../../assets/avatar/hair1.png";
import hair2 from "../../../../assets/avatar/hair2.png";
import hair3 from "../../../../assets/avatar/hair3.png";
import hairFemale1 from "../../../../assets/avatar/female/HAIR_1.png";
import hairFemale2 from "../../../../assets/avatar/female/HAIR_2.png";
import hairFemale3 from "../../../../assets/avatar/female/HAIR_3.png";
import shirt3 from "../../../../assets/avatar/shirt 1.png";

import shirt5 from "../../../../assets/avatar/shirt 2.1.png";
import shirt2_1 from "../../../../assets/avatar/shirt_2_1.png";
import shirt2_2 from "../../../../assets/avatar/shirt_2_2.png";
import shirtFemale1 from "../../../../assets/avatar/female/SHIRT_1.png";
import shirtFemale2 from "../../../../assets/avatar/female/SHIRT_2.png";
import shirtFemale3 from "../../../../assets/avatar/female/SHIRT_3.png";
import shirtFemale2_1 from "../../../../assets/avatar/female/SHIRT_1_1.png";
import shirtFemale2_2 from "../../../../assets/avatar/female/SHIRT_1_2.png";

import item1 from "../../../../assets/avatar/item 1.png";
import item2 from "../../../../assets/avatar/item 2.png";
import item3 from "../../../../assets/avatar/item 3.png";
import itemFemale1 from "../../../../assets/avatar/female/ITEM_1.png";
import itemFemale2 from "../../../../assets/avatar/female/ITEM_2.png";
import group1 from "../../../../assets/avatar/thumbnail/Group 4866272.png";
import group2 from "../../../../assets/avatar/thumbnail/Group 4866273.png";
import group3 from "../../../../assets/avatar/thumbnail/Group 4866274.png";
import thumb1 from "../../../../assets/avatar/thumbnailF/shirt1.png";
import thumb2 from "../../../../assets/avatar/thumbnailF/shirt4.png";
import thumb3 from "../../../../assets/avatar/thumbnailF/shirt5.png";
import thumbhair1 from "../../../../assets/avatar/thumbnailF/hair1.png";
import thumbhair2 from "../../../../assets/avatar/thumbnailF/hair2.png";
import thumbhair3 from "../../../../assets/avatar/thumbnailF/hair3.png";

import clothes3 from "../../../../assets/avatar/thumbnail/Group 4866280.png";
import clothes4 from "../../../../assets/avatar/thumbnail/Group 4866277.png";

import acc1 from "../../../../assets/avatar/thumbnail/Group 4866284.png";
import acc2 from "../../../../assets/avatar/thumbnail/Group 4866275.png";
import acc3 from "../../../../assets/avatar/thumbnail/Group 4866283.png";
// import clothes5 from "../../../../assets/avatar/thumbnail/Group 4866280.png";

// "skintone":{id: "color1", option:{ id: "asdasd"z}}

export const avatarObj = {
  male: {
    skintone: {
      name: "Skintone",
      enable: false,
      multiple: false,
      id: "skintone",
      options: {
        color1: {
          name: "brown",
          id: "color1",
          index: 1,
          color: "#825D47",
          img: avatar1,
          parentId: "skintone",
        },
        color2: {
          name: "white",
          id: "color2",
          index: 1,
          color: "#C7AB9A",
          img: avatar2,
          parentId: "skintone",
        },
        color3: {
          name: "black",
          id: "color3",
          index: 1,
          color: "#AB8B76",
          img: avatar3,
          parentId: "skintone",
        },
      },
    },
    hairstyle: {
      name: "Hairstyle",
      index: 2,
      enable: false,
      multiple: false,
      id: "hairstyle",
      options: {
        hair1: {
          name: "brown",
          id: "hair1",
          index: 2,
          img: hair1,
          optionImg: group3,
          parentId: "hairstyle",
        },
        hair2: {
          name: "white",
          id: "hair2",
          index: 2,
          img: hair2,
          optionImg: group1,
          parentId: "hairstyle",
        },
        hair3: {
          name: "black",
          id: "hair3",
          index: 2,
          img: hair3,
          optionImg: group2,
          parentId: "hairstyle",
        },
      },
    },
    clothes: {
      name: "Clothes",
      index: 3,
      enable: false,
      multiple: false,
      id: "clothes",
      options: {
        shirt1: {
          name: "brown",
          id: "shirt1",
          index: 3,
          img: shirt3,
          optionImg: clothes3,
          parentId: "clothes",
          // options: {
          //   shirt1_1: {
          //     name: "brown",
          //     id: "shirt1_1",
          //     color: "#ead9dd",
          //     index: 3,
          //     img: shirt1,
          //     // optionImg: clothes1,
          //   },
          //   shirt1_2: {
          //     name: "brown",
          //     id: "shirt1_2",
          //     color: "#c0d4e9",
          //     index: 3,
          //     img: shirt2,
          //     // optionImg: clothes2,
          //   },
          //   shirt1_3: {
          //     name: "brown",
          //     id: "shirt1_3",
          //     color: "#fff",
          //     index: 3,
          //     img: shirt3,
          //     // optionImg: clothes3,
          //   },
          // },
        },
        shirt2: {
          name: "white",
          id: "shirt2",
          // img: shirt5,
          index: 3,
          optionImg: clothes4,
          parentId: "clothes",
          options: {
            shirt2_1: {
              name: "brown",
              id: "shirt2_1",
              index: 3,
              color: "#000",
              img: shirt5,
              // optionImg: clothes6,
            },
            shirt2_2: {
              name: "brown",
              id: "shirt2_2",
              index: 3,
              img: shirt2_1,
              color: "#fff",
              // optionImg: clothes4,
            },
            shirt2_3: {
              name: "brown",
              id: "shirt2_3",
              index: 3,
              color: "#000",
              img: shirt2_2,
              // optionImg: clothes6,
            },
          },
        },
      },
    },
    accessories: {
      name: "Accessories",
      enable: false,
      index: 4,
      multiple: true,
      id: "accessories",
      options: {
        item1: {
          name: "brown",
          id: "item1",
          img: item1,
          optionImg: acc1,
          parentId: "accessories",
          index: 4,
        },
        item2: {
          name: "white",
          id: "item2",
          img: item2,
          optionImg: acc2,
          parentId: "accessories",
          index: 5,
        },
        item3: {
          name: "black",
          id: "item3",
          img: item3,
          optionImg: acc3,
          parentId: "accessories",
          index: 6,
        },
      },
    },
  },
  female: {
    skintone: {
      name: "Skintone",
      enable: false,
      multiple: false,
      id: "skintone",
      options: {
        color1: {
          name: "brown",
          id: "color1",
          index: 1,
          color: "#AB8B76",
          img: avatarFemale1,
          parentId: "skintone",
        },
        color2: {
          name: "white",
          id: "color2",
          index: 1,
          color: "#825D47",
          img: avatarFemale2,
          parentId: "skintone",
        },
        color3: {
          name: "black",
          id: "color3",
          index: 1,
          color: "#C7AB9A",
          img: avatarFemale3,
          parentId: "skintone",
        },
      },
    },
    hairstyle: {
      name: "Hairstyle",
      index: 2,
      enable: false,
      multiple: false,
      id: "hairstyle",
      options: {
        hair1: {
          name: "brown",
          id: "hair1",
          index: 2,
          img: hairFemale1,
          optionImg: thumbhair3,
          parentId: "hairstyle",
        },
        hair2: {
          name: "white",
          id: "hair2",
          index: 2,
          img: hairFemale2,
          optionImg: thumbhair2,
          parentId: "hairstyle",
        },
        hair3: {
          name: "black",
          id: "hair3",
          index: 2,
          img: hairFemale3,
          optionImg: thumbhair1,
          parentId: "hairstyle",
        },
      },
    },
    clothes: {
      name: "Clothes",
      index: 3,
      enable: false,
      multiple: false,
      id: "clothes",
      options: {
        shirt1: {
          name: "brown",
          id: "shirt1",
          index: 3,
          optionImg: thumb1,
          parentId: "clothes",
          options: {
            shirt2_2: {
              name: "brown",
              id: "shirt2_2",
              index: 3,
              img: shirtFemale2_1,
              color: "#d0b8c0",
              // optionImg: clothes4,
            },
            shirt2_3: {
              name: "brown",
              id: "shirt1_3",
              index: 3,
              color: "#8fb1bb",
              img: shirtFemale2_2,
              // optionImg: clothes6,
            },
            shirt1_2: {
              name: "brown",
              id: "shirt1_2",
              index: 3,
              img: shirtFemale1,
              color: "#fff",
              // optionImg: clothes4,
            },
          },
        },
        shirt2: {
          name: "white",
          id: "shirt2",
          img: shirtFemale2,
          index: 3,
          optionImg: thumb2,
          parentId: "clothes",
        },
        shirt3: {
          name: "shirt3",
          id: "shirt3",
          img: shirtFemale3,
          index: 3,
          optionImg: thumb3,
          parentId: "clothes",
        },
      },
    },
    accessories: {
      name: "Accessories",
      enable: false,
      index: 4,
      multiple: true,
      id: "accessories",
      options: {
        item1: {
          name: "brown",
          id: "item1",
          img: itemFemale1,
          optionImg: acc1,
          parentId: "accessories",
          index: 4,
        },
        item2: {
          name: "white",
          id: "item2",
          img: itemFemale2,
          optionImg: acc3,
          parentId: "accessories",
          index: 5,
        },
      },
    },
  },
};

export const avatarOptions = {
  male: {
    skintone: {
      name: "Skintone",
      enable: false,
      multiple: false,
      id: "skintone",
      options: [
        {
          name: "brown",
          id: "color1",
          index: 1,
          color: "#825D47",
          img: avatar1,
          parentId: "skintone",
        },
        {
          name: "white",
          id: "color2",
          index: 1,
          color: "#C7AB9A",
          img: avatar2,
          parentId: "skintone",
        },
        {
          name: "black",
          id: "color3",
          index: 1,
          color: "#AB8B76",
          img: avatar3,
          parentId: "skintone",
        },
      ],
    },
    hairstyle: {
      name: "Hairstyle",
      index: 2,
      enable: false,
      multiple: false,
      id: "hairstyle",
      options: [
        {
          name: "brown",
          id: "hair1",
          index: 2,
          img: hair1,
          optionImg: group3,
          parentId: "hairstyle",
        },
        {
          name: "white",
          id: "hair2",
          index: 2,
          img: hair2,
          optionImg: group1,
          parentId: "hairstyle",
        },
        {
          name: "black",
          id: "hair3",
          index: 2,
          img: hair3,
          optionImg: group2,
          parentId: "hairstyle",
        },
      ],
    },
    clothes: {
      name: "Clothes",
      index: 3,
      enable: false,
      multiple: false,
      id: "clothes",
      options: [
        {
          name: "brown",
          id: "shirt1",
          index: 3,
          optionImg: clothes3,
          parentId: "clothes",
          img: shirt3,
          // options: [
          //   {
          //     name: "brown",
          //     id: "shirt1_1",
          //     color: "#ead9dd",
          //     index: 3,
          //     img: shirt1,
          //     // optionImg: clothes1,
          //   },
          //   {
          //     name: "brown",
          //     id: "shirt1_2",
          //     color: "#c0d4e9",
          //     index: 3,
          //     img: shirt2,
          //     // optionImg: clothes2,
          //   },
          //   {
          //     name: "brown",
          //     id: "shirt1_3",
          //     color: "#fff",
          //     index: 3,
          //     img: shirt3,
          //     // optionImg: clothes3,
          //   },
          // ],
        },
        {
          name: "white",
          id: "shirt2",
          // img: shirt5,clothes4
          index: 3,
          optionImg: clothes4,
          options: [
            {
              name: "brown",
              id: "shirt2_3",
              index: 3,
              color: "#fff",
              img: shirt5,
              // optionImg: clothes6,
            },
            {
              name: "brown",
              id: "shirt2_1",
              index: 3,
              img: shirt2_1,
              color: "#d0b8c0",
              // optionImg: clothes4,
            },
            {
              name: "brown",
              id: "shirt2_2",
              index: 3,
              color: "#c0d4e9",
              img: shirt2_2,
              // optionImg: clothes6,
            },
          ],
          parentId: "clothes",
        },
      ],
    },
    accessories: {
      name: "Accessories",
      enable: false,
      index: 4,
      multiple: true,
      id: "accessories",
      options: [
        {
          name: "brown",
          id: "item1",
          img: item1,
          optionImg: acc1,
          parentId: "accessories",
          index: 4,
        },
        {
          name: "white",
          id: "item2",
          img: item2,
          optionImg: acc2,
          parentId: "accessories",
          index: 5,
        },
        {
          name: "black",
          id: "item3",
          img: item3,
          optionImg: acc3,
          parentId: "accessories",
          index: 6,
        },
      ],
    },
  },
  female: {
    skintone: {
      name: "Skintone",
      enable: false,
      multiple: false,
      id: "skintone",
      options: [
        {
          name: "brown",
          id: "color1",
          index: 1,
          color: "#AB8B76",
          img: avatarFemale1,
          parentId: "skintone",
        },
        {
          name: "white",
          id: "color2",
          index: 1,
          color: "#825D47",
          img: avatarFemale2,
          parentId: "skintone",
        },
        {
          name: "black",
          id: "color3",
          index: 1,
          color: "#C7AB9A",
          img: avatarFemale3,
          parentId: "skintone",
        },
      ],
    },
    hairstyle: {
      name: "Hairstyle",
      index: 2,
      enable: false,
      multiple: false,
      id: "hairstyle",
      options: [
        {
          name: "brown",
          id: "hair1",
          index: 2,
          img: hairFemale1,
          optionImg: thumbhair3,
          parentId: "hairstyle",
        },
        {
          name: "white",
          id: "hair2",
          index: 2,
          img: hairFemale2,
          optionImg: thumbhair1,
          parentId: "hairstyle",
        },
        {
          name: "black",
          id: "hair3",
          index: 2,
          img: hairFemale3,
          optionImg: thumbhair2,
          parentId: "hairstyle",
        },
      ],
    },
    clothes: {
      name: "Clothes",
      index: 3,
      enable: false,
      multiple: false,
      id: "clothes",
      options: [
        {
          name: "brown",
          id: "shirt1",
          index: 3,
          optionImg: thumb1,
          parentId: "clothes",
          options: [
            {
              name: "brown",
              id: "shirt2_2",
              index: 3,
              img: shirtFemale2_1,
              color: "#d0b8c0",
              // optionImg: clothes4,
            },
            {
              name: "brown",
              id: "shirt1_3",
              index: 3,
              color: "#8fb1bb",
              img: shirtFemale2_2,
              // optionImg: clothes6,
            },
            {
              name: "brown",
              id: "shirt1_2",
              index: 3,
              img: shirtFemale1,
              color: "#fff",
              // optionImg: clothes4,
            },
          ],
        },
        {
          name: "white",
          id: "shirt2",
          img: shirtFemale2,
          index: 3,
          optionImg: thumb2,
          parentId: "clothes",
        },
        {
          name: "shirt3",
          id: "shirt3",
          img: shirtFemale3,
          index: 3,
          optionImg: thumb3,
          parentId: "clothes",
        },
      ],
    },
    accessories: {
      name: "Accessories",
      enable: false,
      index: 4,
      multiple: true,
      id: "accessories",
      options: [
        {
          name: "brown",
          id: "item1",
          img: itemFemale1,
          optionImg: acc1,
          parentId: "accessories",
          index: 4,
        },
        {
          name: "white",
          id: "item2",
          img: itemFemale2,
          optionImg: acc3,
          parentId: "accessories",
          index: 5,
        },
      ],
    },
  },
};
