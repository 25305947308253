import moment from "moment";
import { ContentType, ContentType2 } from "../AppConstants/TypeConstants.js";
import {
  Agenda,
  Course,
  CourseAssessment,
  CourseAssessmentSubmit,
  CourseChapter,
  Episode,
  LoyalityPoints,
  PageVisit,
  Season,
  Share,
  Subtab,
  WebinarRegister,
} from "./EventSchema.ts";
import {
  SignUp,
  Login,
  Webinar,
  Global,
  OTPVerify,
  OTPSend,
  FeedbackSubmit,
  Rating,
  Base,
  Speaker,
  ArrayJoin,
  Note,
  Search,
  SearchClick,
} from "./EventSchema.ts";
import { SEARCH_PAGE_CONST } from "../scenes/VideoSearchPage/NewHits/index.js";

export const defaultNotAvailableText = "N/A";

// global
export const getGlobalData = (data: Global): Global => {
  const eventData: Global = {
    Page: data["Page"] || defaultNotAvailableText,
    URL: data["URL"] || defaultNotAvailableText,
    Section: data["Section"] || defaultNotAvailableText, // Screen
  };
  return eventData;
};

// page visited
export const getPageVisitedData = (data: PageVisit): PageVisit => {
  const eventData: PageVisit = {
    Screen: data["Screen"] || defaultNotAvailableText,
    URL: data["URL"] || defaultNotAvailableText,
    Platform: data["Platform"] || defaultNotAvailableText, // Screen
  };
  return eventData;
};

// sign up
export const getSignUpData = (data: any): SignUp => {
  const eventData: SignUp = {
    Name: `${data?.firstName ?? ''} ${data?.lastName ?? ''}` || defaultNotAvailableText,
    "Email": data?.email || defaultNotAvailableText,
    "Phone": data?.phoneNumber || defaultNotAvailableText,
    Campaign: data?.utm_campaign || defaultNotAvailableText,
    Medium: data?.utm_medium || defaultNotAvailableText,
    Source: data?.utm_source || defaultNotAvailableText,
    Referrer: data?.userMetaData?.registrationSource || defaultNotAvailableText,
    Status: data?.Status ?? defaultNotAvailableText,
    "Method Used": data?.["Method Used"] || defaultNotAvailableText,
    "Status Message": data["Status Message"] || defaultNotAvailableText,
    "User Profession": data?.profession || defaultNotAvailableText,
    "User Speciality": data?.speciality || defaultNotAvailableText,
    "User Entered Location": {
      city: data?.city || defaultNotAvailableText,
      state: data?.state || defaultNotAvailableText,
      country: data?.country || defaultNotAvailableText,
    },
    "Employee Code": data?.employeeCode || defaultNotAvailableText,
  };
  return eventData;
};

// login
export const getLoginData = (data: any): Login => {
  const eventData: Login = {
    "Phone": data["Phone"] || defaultNotAvailableText,
    "Method Used": data["Method Used"] || defaultNotAvailableText,
    Status: data["Status"] ?? defaultNotAvailableText,
    "Status Message": data["Status Message"] || defaultNotAvailableText,
  };
  return eventData;
};

// otp verify
export const getOTPVerificationData = (data: any): OTPVerify => {
  const eventData: OTPVerify = {
    Medium: data["Medium"] || defaultNotAvailableText,
    Status: data["Status"] ?? defaultNotAvailableText,
    "Status Message": data["Status Message"] || defaultNotAvailableText,
  };
  return eventData;
};

// otp send
export const getOTPSendData = (data: any): OTPSend => {
  const eventData: OTPSend = {
    Retries: data["Retries"] || defaultNotAvailableText,
    Medium: data["Medium"] || defaultNotAvailableText,
    Status: data["Status"] ?? defaultNotAvailableText,
    "Status Message": data["Status Message"] || defaultNotAvailableText,
  };
  return eventData;
};

// feedback data
export const getFeedbackData = (data: any): FeedbackSubmit => {
  const eventData: FeedbackSubmit = {
    Platform: data["Platform"] || defaultNotAvailableText,
    URL: data["URL"] || defaultNotAvailableText,
    "Feedback Type": data["Feedback Type"] || defaultNotAvailableText,
    Message: data["Message"] || defaultNotAvailableText,
    Email: data["Email"] || defaultNotAvailableText,
    Phone: data["Phone"] || defaultNotAvailableText,
  };
  return eventData;
};

// rate data
export const getRatingData = (data: any): Rating => {
  const eventData: Rating = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": data["targetType"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    Rate: data["Rate"] || defaultNotAvailableText,
  };
  return eventData;
};

// like data
export const getLikeDislikeData = (data: any): Base => {
  const eventData: Base = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": data["targetType"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,
  };
  return eventData;
};

// speaker click
export const getSpeakerData = (data: any): Speaker => {
  const eventData: Speaker = {
    "Speaker Id": data["Speaker Id"] || defaultNotAvailableText,
    "Speaker Name": data["Speaker Name"] || defaultNotAvailableText,
  };
  return eventData;
};

// note created
export const getNoteData = (data: any): Note => {
  const eventData: Note = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": data["targetType"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    "Start Time": data["Start Time"] || defaultNotAvailableText,
    "End Time": data["End Time"] || defaultNotAvailableText,
    Note: data["Note"] || defaultNotAvailableText,
  };
  return eventData;
};

// search event
export const getSearchData = (data: any): Search => {
  const eventData: Search = {
    "Search Term": data["Search Term"] || defaultNotAvailableText,
    // 'Filters': data["Filters"],
    Status: data["Status"] ?? defaultNotAvailableText,
    "Status Message": data["Status Message"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getSearchClickItemData = (data: any): SearchClick => {
  const eventData: SearchClick = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": data["targetType"]
      ? data["targetType"]
      : SEARCH_PAGE_CONST[
      data["type"]?.toLowerCase() || data["videoType"]?.toLowerCase()
      ]?.["heading"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    "Search Result Index": data["index"] ?? defaultNotAvailableText,
    "Search Term": data["Search Term"] || defaultNotAvailableText,
    // 'Filters': data["Filters"],
  };
  return eventData;
};

export const getSeasonData = (data: any): Season => {
  const eventData: Season = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2.Season || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    "Series ID":
      data["Series ID"] || data["seriesId"] || defaultNotAvailableText,
    "Series Title": data["Series Title"] || defaultNotAvailableText,
    "Season Index":
      data["Season Index"] ?? data["seasonIndex"] ?? defaultNotAvailableText,
  };
  return eventData;
};

export const getEpisodeData = (data: any): Episode => {
  const eventData: Episode = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2.Episode || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    "Series ID": data["Series ID"] || defaultNotAvailableText,
    "Season ID": data["Season ID"] || defaultNotAvailableText,
    "Series Title": data["Series Title"] || defaultNotAvailableText,
    "Season Title": data["Season Title"] || defaultNotAvailableText,
    "Season Index": data["Season Index"] ?? defaultNotAvailableText,
    "Episode Index": data["Episode Index"] ?? defaultNotAvailableText,
  };
  return eventData;
};

export const getSubtabData = (data: any): Subtab => {
  const eventData: Subtab = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": data["targetType"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    Section: data["Section"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getStandaloneVideoData = (data: any): Base => {
  const eventData: Base = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": data["targetType"]
      ? data["targetType"]
      : data?.["videoType"] === ContentType.SERIES
        ? ContentType2.Episode
        : data?.["videoType"] === "trailer"
          ? ContentType2.Trailer
          : data?.["videoType"] === "standalone"
            ? ContentType2["Standalone Video"]
            : defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getCourseData = (data: any): Course => {
  const eventData: Course = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2.Course || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    Marks: data["earnedPoints"] || defaultNotAvailableText,
    Private: data["private"] || defaultNotAvailableText,
    "Closed Group": data["closedGroup"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getCourseChapterData = (
  courseData: any,
  data: any
): CourseChapter => {
  const eventData: CourseChapter = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2["Course Chapter"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    "Course ID": data["courseId"] || defaultNotAvailableText,
    "Course Title": courseData["title"] || defaultNotAvailableText,
    Marks: data["score"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getCourseAssessmentData = (
  courseData: any,
  data: any
): CourseAssessment => {
  const eventData: CourseAssessment = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type":
      ContentType2["Course Assessment"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    "Course ID": data["courseId"] || defaultNotAvailableText,
    "Course Title": courseData["title"] || defaultNotAvailableText,
    "Passing Criteria": data["passingCriteria"] || defaultNotAvailableText,
    Marks: data["Score"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getCourseAssessmentSubmitData = (
  courseData: any,
  data: any
): CourseAssessmentSubmit => {
  const eventData: CourseAssessmentSubmit = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type":
      ContentType2["Course Assessment"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    "Course ID": data["courseId"] || defaultNotAvailableText,
    "Course Title": courseData["title"] || defaultNotAvailableText,
    "Passing Criteria": data["passingCriteria"] || defaultNotAvailableText,
    Marks: data["Score"] || defaultNotAvailableText,

    Result: data["Result"] || defaultNotAvailableText,
    Percentage: data["Score"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getWebinarClickData = (data: any): Webinar => {
  const eventData: Webinar = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2.Webinar || defaultNotAvailableText,
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,
    "Start Time":
      new Date(data["startTime"].toDate()).toUTCString() ||
      defaultNotAvailableText,
    "End Time":
      new Date(data["endTime"].toDate()).toUTCString() ||
      defaultNotAvailableText,
  };
  return eventData;
};

export const getWebinarRegisterData = (data: any): WebinarRegister => {
  const eventData: WebinarRegister = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2.Webinar || defaultNotAvailableText,
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,
    "Start Time":
      new Date(data["startTime"].toDate()).toUTCString() ||
      defaultNotAvailableText,
    "End Time":
      new Date(data["endTime"].toDate()).toUTCString() ||
      defaultNotAvailableText,

    Status: data["Status"] ?? defaultNotAvailableText,
    "Status Message": data["message"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getAgendaData = (data: any): Agenda => {
  const eventData: Agenda = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2.Agenda || defaultNotAvailableText,
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,
    "Start Time":
      moment(data["time"]).format("MMMM Do YYYY, h:mm:ss a") ||
      defaultNotAvailableText,
    "Webinar ID": data["webinarId"] || defaultNotAvailableText,
    "Webinar Title": data["Webinar Title"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getSeriesBaseData = (data: any): Base => {
  const eventData: Base = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": ContentType2.Series || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,
  };
  return eventData;
};

export const getShareData = (data: any): Share => {
  const eventData: Share = {
    "Content ID": data["id"] || defaultNotAvailableText,
    "Content Title": data["title"] || defaultNotAvailableText,
    "Content Description": data["description"] || defaultNotAvailableText,
    "Content Type": data["from"] || defaultNotAvailableText, // "Course" | "Course Chapter" | "Course Assessment" | "Webinar" | "Agenda" | "Standalone Video" | "Episode" | "Series" | "Season" | "Space" | "Podcast" | "Reel" | "Story",
    "Content Tags": data["tags"]
      ? ArrayJoin(data["tags"])
      : defaultNotAvailableText,
    "Content Speciality": data["speciality"]
      ? ArrayJoin(data["speciality"])
      : defaultNotAvailableText,
    "Content Therapy": data["therapy"]
      ? ArrayJoin(data["therapy"])
      : defaultNotAvailableText,
    "Content Misc": data["misc"]
      ? ArrayJoin(data["misc"])
      : defaultNotAvailableText,
    "Landscape Thumbnail":
      data["desktopThumbnailUrl"] ||
      data["thumbnailUrl"] ||
      defaultNotAvailableText,
    "Portrait Thumbnail": data["thumbPortraitUrl"] || defaultNotAvailableText,

    Medium: data["Medium"] || defaultNotAvailableText,
  };
  return eventData;
};


export const getLoyaltyPointsData = (data: LoyalityPoints): LoyalityPoints => {
  const eventData: LoyalityPoints = {
   "Loyalty Points": data["Loyalty Points"] || 0,
   Type: data["Type"] || defaultNotAvailableText,
   Timestamp: data["Timestamp"] || defaultNotAvailableText, // Screen
  };
  return eventData;
};
