// export const WATCHTRAILER_ANALYTICS_EVENT = "watch_Trailer";
export const ADDTOCALENDAR_ANALYTICS_EVENT = "addToCalendar";
export const INVITEYOURFRIEND_EVENT_EMAIL = "inviteYourFriend_email";
export const INVITEYOURFRIEND_EVENT_FACEBOOK = "inviteYourFriend_facebook";
export const INVITEYOURFRIEND_EVENT_TWITTER = "inviteYourFriend_twitter";
export const INVITEYOURFRIEND_EVENT_WHATSAPP = "inviteYourFriend_whatsapp";
export const INVITEYOURFRIEND_EVENT_LINKEDIN = "inviteYourFriend_linkedin";
export const INVITEYOURFRIEND_EVENT_BTN = "inviteYourFriend_btn";
export const COURSE_SHARE_BTN = "course_share_btn";
export const TAG_CLICKED = "tag_click";
export const VIDEO_CLICK = "video_click";
export const VIDEO_PLAY = "video_play";
export const VIDEO_LIKE_BTN_CLICK = "video_like_btn_click";
export const CHAPTER_LIKE_BTN_CLICK = "chapter_like_btn_click";
export const CHAPTER_DISLIKE_BTN_CLICK = "chapter_dislike_btn_click";
export const CHAPTER_RATING_CLICK = "chapter_rating_click";
export const VIDEO_DISLIKE_BTN_CLICK = "video_dislike_btn_click";
export const VIDEO_SHARE_BTN_CLICK = "video_share_btn_click";
export const VIDEO_NOTE_BTN_CLICK = "video_note_btn_click";
export const EPISODE_NOTE_BTN_CLICK = "episode_note_btn_click";
export const VIDEO_WATCH_LATER_BTN_CLICK = "video_watch_later_btn_click";
export const SERIES_WATCH_LATER_BTN_CLICK = "series_watch_later_btn_click";
export const VIDEO_RATING_CLICK = "video_rating_click";
export const VIDEO_TABS_CLICK = "video_tabs_click";
export const SERIES_VIDEO_CLICK = "series_video_click";
export const SERIES_VIDEO_LIKE_BTN_CLICK = "series_video_like_btn_click";
export const SERIES_VIDEO_DISLIKE_BTN_CLICK = "series_video_dislike_btn_click";
export const SERIES_VIDEO_SHARE_BTN_CLICK = "series_video_share_btn_click";
export const SERIES_VIDEO_NOTE_BTN_CLICK = "series_video_note_btn_click";
export const SERIES_VIDEO_RATING_CLICK = "series_video_rating_click";
export const SERIES_VIDEO_TABS_CLICK = "series_video_tabs_click";
export const WEBINAR_TABS_CLICK = "webinar_tabs_click";
export const ACADEMY_TABS_CLICK = "academy_tabs_click";
export const WEBINAR_PAGE_TABS_CLICK = "webinar_page_tabs_click";
export const WEBINAR_PAGE_ATTEND_CLICK = "webinar_page_attend_click";
export const WEBINAR_PAGE_SHARE_CLICK = "webinar_page_share_click";
export const WEBINAR_SHARE_CLICK = "webinar_share_click";
export const REEL_SHARE_CLICK = "reel_share_click";
export const REEL_LIKE_CLICK = "reel_like_click";
export const REEL_HOME_CLICK = "reel_home_click";
export const HOME_LATEST_SHOWS_CLICK = "home_latest_shows_click";
export const HOME_HOT_TOPICS_CLICK = "home_hot_topics_click";
export const SEASON_WATCH_NOW_BTN_CLICK = "season_watch_now_btn_click";
export const SEASON_MODAL_TABS_CLICK = "season_modal_tabs_click";
export const TOP_10_CLICK = "top_10_click";
export const DOCUMENTARY_BTN_CLICK = "doucmentary_btn_click";
export const HOME_PODCAST_CLICK = "home_podcast_click";
export const DRAWER_OPEN_CLICK = "drawer_open_click";
export const SEARCH_BTN_CLICK = "search_btn_click";
export const SEARCH_INPUT_CLICK = "search_input_click";
export const HEADER_SEARCH_CLICK = "Header_Search"; // for attrina
export const SPEAKER_CLICK = "speaker_click";
export const HOME_UPCOMING_WEBINAR_ATTEND_CLICK =
  "home_upcoming_webinar_attend_click";
export const WEBINAR_PAGE_CAROUSEL_ATTEND_CLICK =
  "webinar_page_carousel_attend_click";
export const HOME_UPCOMING_WEBINAR_SHARE_CLICK =
  "home_upcoming_webinar_share_click";
export const WEBINAR_PAGE_CAROUSEL_SHARE_CLICK =
  "webinar_page_carousel_share_click";
export const RECENTLY_ADDED_BANNER_CLICK = "recently_added_banner_click";
// export const VIDEO_KEYFRAME_CLICK = "video_keyframe_click";
// export const VIDEO_TIMESPENT = "video_timespent";
export const CERTIFICATE_CLICK = "certificate_click";
export const DOWNLOAD_CERTIFICATE = "certificate_download";
export const FEEDBACK_CLICK = "feedback_click";
export const FEEDBACK_SUBMIT = "feedback_submit";
export const RESOURCES_CLICK = "resources_click";
export const AGENDA_LIKE_BTN_CLICK = "agenda_like_btn_click";
export const AGENDA_UNLIKE_BTN_CLICK = "agenda_unlike_btn_click";
export const AGENDA_RATING_BTN_CLICK = "agenda_rating_btn_click";
export const WEBINAR_CHAT_BTN_CLICK = "webinar_chat_btn_click";
export const QNA_INTERATCION = "qna_interaction";
export const POLL_INTERACTION = "poll_interaction";
export const LIVE_INTERACTION_CURIOUS_CLICK = "live_interaction_curious_click";
export const LIVE_INTERACTION_AMAZING_CLICK = "live_interaction_amazing_click";
export const LIVE_INTERACTION_THINKING_CLICK =
  "live_interaction_thinking_click";
export const BANNER_WATCH_NOW_BTN_CLICK = "banner_watch_now_btn_click";
export const ANNOUNCEMENT_WATCH_NOW_BTN_CLICK =
  "announcement_watch_now_btn_click";
// export const SESSION_ATTENDED = "session_attended";
export const WEBINAR_REGISTER = "webinar_register";
export const NOTIFICATION_RECEIVED = "notification_received";
export const NOTIFICATION_INTERACTED = "notification_interacted";

export const EXCLUSIVE_CONTENT_NOW_BTN_CLICK =
  "exclusive_content_now_btn_click";
export const PRIVATE_CONTENT_VERIFY = "privateContentVerify";
export const ACADEMY_COURSE_CLICK = "academyCourseClick";
export const ACADEMY_CHAPTER_CLICK = "academyChapterClick";
export const ASSESSMENT_SUBMIT_BTN = "assessment_submit_btn";
export const LOGIN_CLICK = "login_click";
export const LOGOUT_BTN = "logout_btn";
export const OTP_SEND_BTN = "otp_send_btn";
export const OTP_RESEND_BTN = "otp_resend_btn";
export const OTP_VERIFIED_BTN = "otp_verified_btn";
export const OTP_CANCEL_BTN = "otp_cancel_btn";
