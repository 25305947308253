import { WEBINAR_CHAT_DB } from "../../AppConstants/RealtimeDB";
import { database } from "../../firebase/firebase";
import firebase from "firebase/app";

export const user_ActiveStatus = (user, webinarId) => {
  let isOnlineForDatabase = {
    StateMode: "online",
  };
  let userStatusDatabaseRef = database.ref(
    WEBINAR_CHAT_DB.WEBINAR_CHAT +
      "/" +
      webinarId +
      "/chat/" +
      WEBINAR_CHAT_DB.USER_STATUS +
      "/" +
      user.uid
  );
  let onDisconnectRef = userStatusDatabaseRef.onDisconnect();
  onDisconnectRef.remove().then(() => {
    userStatusDatabaseRef.set({
      ...isOnlineForDatabase,
      name: user.displayName ?? "",
      id: user.uid,
      email: user.email,
    });
  });
};

export const getUserActiveStatus = (webinarId, callback) => {
  let userStatusDatabaseRef = database.ref(
    WEBINAR_CHAT_DB.WEBINAR_CHAT +
      "/" +
      webinarId +
      "/chat/" +
      WEBINAR_CHAT_DB.USER_STATUS
  );

  userStatusDatabaseRef.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        callback(data);
      }
    },
    (err) => {
      console.error(err);
    }
  );
};

export const SendMessageInWebinar = (
  webinarID,
  message,
  roomId,
  name,
  currentUid,
  chatUser
) => {
  //set data in the room message database
  // console.log("messageData", chatUser);
  //------------------------------------------------------
  let messageData = {
    name: name,
    id: currentUid,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    message: message,
    room: roomId,
  };
  let messageRef = database
    .ref(
      WEBINAR_CHAT_DB.WEBINAR_CHAT +
        "/" +
        webinarID +
        "/chat" +
        WEBINAR_CHAT_DB.ROOM_MESSAGE +
        "/" +
        roomId
    )
    .push();

  messageRef.set({
    ...messageData,
  });

  //------------------------------------------------------

  let chatMetaData = {
    message: message,
    room: roomId,
    chatWith: chatUser.id,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    name: name,
    id: currentUid,
    [currentUid]: name,
    [chatUser.id]: chatUser.firstName
      ? `${chatUser.firstName} ${chatUser.lastName}`
      : chatUser.name,
    participants: [currentUid, chatUser.id],
    chatWithName: chatUser.name
      ? chatUser.name
      : `${chatUser.firstName} ${chatUser.lastName}`,
  };
  const currentUserRoomMetadataNodeRef = database.ref(
    WEBINAR_CHAT_DB.WEBINAR_CHAT +
      "/" +
      webinarID +
      "/chat" +
      WEBINAR_CHAT_DB.ROOM_META_DATA +
      "/" +
      currentUid +
      "/" +
      roomId
  );
  currentUserRoomMetadataNodeRef
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        currentUserRoomMetadataNodeRef.update({
          chatWith: chatUser.id,
          id: chatUser.id,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          // chatWithName: chatUser.name
          //   ? chatUser.name
          //   : `${chatUser.firstName} ${chatUser.lastName}`,
          // chatUserPhoto: chatUser.photoUrl,
        });
      } else {
        currentUserRoomMetadataNodeRef.set({
          ...chatMetaData,
          chatWith: chatUser.id,
          id: chatUser.id,
          chatWithName: chatUser.name
            ? chatUser.name
            : `${chatUser.firstName} ${chatUser.lastName}`,
          // chatUserPhoto: chatUser.photoUrl,
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });

  const otherUserRoomMetadataNodeRef = database.ref(
    WEBINAR_CHAT_DB.WEBINAR_CHAT +
      "/" +
      webinarID +
      "/chat" +
      WEBINAR_CHAT_DB.ROOM_META_DATA +
      "/" +
      chatUser.id +
      "/" +
      roomId
  );
  otherUserRoomMetadataNodeRef
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        otherUserRoomMetadataNodeRef.update({
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          chatWith: currentUid,

          id: currentUid,
          chatWithName: `${name}`,
          // chatUserPhoto: chatUser.photoUrl,
        });
      } else {
        otherUserRoomMetadataNodeRef.set({
          ...chatMetaData,
          chatWith: currentUid,

          id: currentUid,
          chatWithName: `${name}`,
          // chatUserPhoto: chatUser.photoUrl,
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // otherUserRoomMetadataNodeRef.update({
  //   ...chatMetaData,
  //   chatWith: currentUid,

  //   id: currentUid,
  //   chatWithName: `${name}`,
  //   // chatUserPhoto: chatUser.photoUrl,
  // });

  // console.log("currentUid", currentUid);

  //------------------------------------------------------

  //add notification to other usernode and remove current userNotification if any
  const currentUserNotificationNode = database.ref(
    `/chat/${WEBINAR_CHAT_DB.USER_NOTIFICATION}/${currentUid}/${chatUser.id}`
  );
  currentUserNotificationNode.remove();

  const otherUserNotificationNode = database.ref(
    `${WEBINAR_CHAT_DB.WEBINAR_CHAT}/${webinarID}/chat/${WEBINAR_CHAT_DB.USER_NOTIFICATION}/${chatUser.id}/${currentUid}`
  );
  otherUserNotificationNode.update({
    count: firebase.database.ServerValue.increment(1),
    message: message,
    roomId: roomId,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    name: name,
    id: currentUid,
  });

  //------------------------------------------------------
};

export const getMessagebyRoomId = (webinarID, roomId, callback) => {
  const chatDatabyRoomIdref = database.ref(
    `${WEBINAR_CHAT_DB.WEBINAR_CHAT}/${webinarID}/chat/${WEBINAR_CHAT_DB.ROOM_MESSAGE}/${roomId}`
  );

  chatDatabyRoomIdref.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        callback(data);
      }
    },
    (err) => {
      console.error(err);
    }
  );
};

export const singleTypingIndicator = (webinarID, roomId, userId, status) => {
  const typingIndicatorRef = database.ref(
    `${WEBINAR_CHAT_DB.WEBINAR_CHAT}/${webinarID}/chat/${WEBINAR_CHAT_DB.ROOM_TYPING_INDICATOR}/${roomId}`
  );
  typingIndicatorRef.update({
    [userId]: status,
  });
};

export function getTypingStatus(webinarID, roomId, otherUserId, callback) {
  let userRef = database.ref(
    `${WEBINAR_CHAT_DB.WEBINAR_CHAT}/${webinarID}/chat/${WEBINAR_CHAT_DB.ROOM_TYPING_INDICATOR}/${roomId}/${otherUserId}`
  );

  userRef.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "noStatus", message: "No Typing Status found" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
}

export const getLastChatUsers = (webinarID, userId, callback) => {
  let lastChatUser = database.ref(
    `${WEBINAR_CHAT_DB.WEBINAR_CHAT}/${webinarID}/chat/${WEBINAR_CHAT_DB.ROOM_META_DATA}/${userId}`
  );

  lastChatUser.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "noStatus", message: "No Typing Status found" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
};

export const getNotificationData = (webinarID, userId, callback) => {
  let notificationData = database.ref(
    `${WEBINAR_CHAT_DB.WEBINAR_CHAT}/${webinarID}/chat/${WEBINAR_CHAT_DB.USER_NOTIFICATION}/${userId}`
  );

  notificationData.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "noStatus", message: "No Typing Status found" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
};

export function removeNotification(webinarID, userId, otherUserId) {
  let userRef = database.ref(
    `${WEBINAR_CHAT_DB.WEBINAR_CHAT}/${webinarID}/chat/${WEBINAR_CHAT_DB.USER_NOTIFICATION}/${userId}/${otherUserId}`
  );
  userRef.remove();
}
