//Imports
import { USER_NOTES } from "../../../AppConstants/CollectionConstants";

// Firebase
import firebase, { firestore } from "../../../firebase/firebase";

const updateNote = async ({ targetId, userId, index, noteContent }) => {
  try {
    if (!userId && !targetId) {
      throw new Error("Please provide a userId and targetId");
    }

    const noteId = `${userId}_${targetId}`;
    const docRef = firestore.collection(USER_NOTES).doc(noteId);

    await firestore.runTransaction(async (transaction) => {
      let doc = await transaction.get(docRef);
      if (!doc.exists) {
        const err = {
          code: "NotValidId",
          message: "No Notes Found",
        };
        throw err;
      }

      let _data = doc.data();
      if (index >= _data.content.length) {
        const err = {
          code: "Not Valid Index",
          message: "Please provide valid index",
        };

        throw err;
      }

      _data.content[index] = {
        startTime: _data.content[index].startTime,
        endTime: _data.content[index].endTime,
        creationTimestamp: _data.content[index].creationTimestamp,
        updatedTimestamp: firebase.firestore.Timestamp.now(),
        note: noteContent,
        id: _data.content[index].id,
      };

      transaction.update(docRef, _data);
    });

    return noteId;
  } catch (error) {
    console.log("Error:: User:: ", error);
    throw error;
  }
};

const put = {
  updateNote,
};

export default put;
