import { ACADEMY_PROGRESSION_COLLECTION, STUDENTS_COLLECTION } from '../../../AppConstants/CollectionConstants';
import { ErrCode_DocNotFound } from '../../../AppConstants/ErrorCodes';
import { firestore } from '../../../firebase/firebase';

let ProgressionReference = null;

const getCourseProgression = async (courseId, studentId) => {
	try {
		let docRef = firestore.collection(ACADEMY_PROGRESSION_COLLECTION).doc(`${courseId}_${studentId}`);
		let docQuery = await docRef.get();
		if (!docQuery.exists) {
			let err = {
				code: ErrCode_DocNotFound,
				message: 'No student progression found'
			};
			throw err;
		}

		return docQuery.data();
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const getTop10CourseStudents = async (courseId, lastDocRef, limit = 10) => {
	try {
		let docRef = firestore
			.collection(ACADEMY_PROGRESSION_COLLECTION)
			.where('courseId', '==', courseId)
			.orderBy('earnedPoints', 'desc')
			.limit(limit);

		if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
		const query = await docRef.get();

		if (query.empty) {
			return { data: [], lastDocRef: null };
		}

		lastDocRef = query.docs[query.docs.length - 1];
		let _data = [];

		for (let doc of query.docs) {
			let studentData = {
				userImage: 'https://picsum.photos/208/208',
				fullname: 'User',
				place: 'Place',
				overallScore: '--'
			};

			let studentDoc = await firestore.collection(STUDENTS_COLLECTION).doc(doc.data()['studentId']).get();
			if (studentDoc.exists) {
				studentData = studentDoc.data();
			}

			studentData['overallScore'] = doc.data()['earnedPoints'];

			_data.push({ ...doc.data(), studentData });
		}

		return { data: _data, lastDocRef };
	} catch (error) {
		console.error('Error :: ', error);
		throw error;
	}
};

const attachProgressionListener = (id, callback = () => console.log('noFunFound')) => {
	const ref = firestore.collection(ACADEMY_PROGRESSION_COLLECTION).doc(id);
	ProgressionReference = ref.onSnapshot(
		async (doc) => {
			if (!doc.exists) {
				callback(null);
			}

			callback(doc.data());
		},
		(err) => {
			callback(null, err);
		}
	);
};

const removeProgressionListener = () => {
	if (ProgressionReference) {
		ProgressionReference();
	}
};

const get = {
	getCourseProgression,
	attachProgressionListener,
	removeProgressionListener,
	getTop10CourseStudents
};

export default get;
