import { usersAPI } from "../apis/usersAPI"

export const UserLoginHookActionType = {
    LIKE: 'like',
    WEBINAR_REGISTER: 'webinar_register'
}

const handleUserLoginHookAction = (data = {}) => {
    return new Promise(async (resolve, rej) => {
        let extraData = { Status: false, message: "" };
        let { type, payload } = data
        try {
            switch (type) {
                case UserLoginHookActionType.WEBINAR_REGISTER:
                    const res = await usersAPI.webinarRegister(
                        {
                            ...payload.data
                        }
                    );
                    if (res.data.status == "Successfull") {
                        extraData["Status"] = true;
                        payload.fn(extraData);
                        resolve({
                            type,
                            res: res.data
                        })
                    }
                    break;
                default:
                    rej({ code: "Invalid-type", message: "Please pass a valid" })
            }
        } catch (error) {
            payload.fn(extraData);
            const { data: err } = error;
            extraData["Status"] = false;
            extraData["message"] = err.message;
            rej(err)
        }
    })
}

export const exceuteUserLoginHook = () => {
    return new Promise(async (res, rej) => {
        try {
            let arr = window.userLoginHook
            let data = []
            if (arr.length !== 0) {
                // console.log(arr)
                data = await Promise.allSettled(arr.map(data => handleUserLoginHookAction(data)))
                // console.log(data)
            }
            res(data)
        } catch (error) {
            console.error(error)
            rej(error)
        }
    })
}

export const addUserLoginHook = (type, payload) => {
    if (type && payload)
        window.userLoginHook.push({ type, payload })
}

export const resetUserLoginHook = () => {
    window.userLoginHook = []
}

// let bag = []
// const mid = (fn, ...args) =>{
//     bag.push({
//         "funref": fn, 
//         "args": args
//     })
// }
// const exceuteBag = () => {
//     bag.forEach(a => a.funref(...a.args);
// });