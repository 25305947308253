//Imports
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	RATINGS_COLLECTION,
	AGENDA_COLLECTION,
	WEBINAR_COLLECTION,
	SERIES_COLLECTION,
	SEASON_COLLECTION,
	USER_COLLECTION,
	COURSE_CHAPTERS_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { ScorePoints } from '../../../AppConstants/ScorePoints';
import { SourceType, VideoType, LikeType, RatingType, ContentType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const createRating = async (targetId, userId, rating, targetType = ContentType.VIDEO) => {
	try {
		const COLLECTION = {
			[ContentType.VIDEO]: VIDEO_COLLECTION,
			[ContentType.WEBINAR]: WEBINAR_COLLECTION,
			[ContentType.AGENDA]: AGENDA_COLLECTION,
			[ContentType.SERIES]: SERIES_COLLECTION,
			[ContentType.SEASON]: SEASON_COLLECTION,
			[ContentType.CHAPTER]: COURSE_CHAPTERS_COLLECTION
		};
		let targetCollection = COLLECTION[targetType];
		const docRef = firestore.collection(targetCollection).doc(targetId);
		const RatingRef = firestore.collection(RATINGS_COLLECTION).doc(`${userId}+${targetId}`);
		const UserRef = firestore.collection(USER_COLLECTION).doc(userId);

		const _rating = await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			let ratingDoc = await transcation.get(RatingRef);
			let UserDoc = await transcation.get(UserRef);

			if (ratingDoc.exists) {
				let err = {
					code: 'AlreadyRated',
					message: 'This Time has Already Been Rated'
				};
				throw err;
			}
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No VideoId Found'
				};
				throw err;
			}

			if (!UserDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No User Found'
				};
				throw err;
			}

			transcation.set(RatingRef, {
				id: `${userId}+${targetId}`,
				targetId: targetId,
				type: targetType,
				user: userId,
				rating,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				points: ScorePoints.RATE
			});

			// Weird logic for rating
			let currentRating = parseFloat(doc.data().rating) || 0;
			let currentRatingGiven = doc.data().ratingsgiven;
			currentRating = currentRating ? currentRating : 0;
			currentRatingGiven = currentRatingGiven ? currentRatingGiven : 0;

			const originalRating = currentRating * currentRatingGiven;
			const addedRating = originalRating + rating;
			const totalUsers = currentRatingGiven + 1;
			const avgRating = addedRating / totalUsers;

			transcation.update(docRef, {
				ratingsgiven: firebase.firestore.FieldValue.increment(1),
				rating: parseFloat(avgRating.toFixed(2))
			});

			// Added points for rating
			transcation.update(UserRef, {
				score: UserDoc.data().score
					? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.RATE))
					: ScorePoints.RATE
			});

			return parseFloat(avgRating.toFixed(2));
		});
		return _rating;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createRating
};

export default post;
