import { createContext, useContext, useEffect } from "react";
import aa from "search-insights";
import { algoliaCustomEvents } from "../../AppConstants/AlgoliaSearchConstants";
import { analytics } from "../../firebase/firebase";
import { isProd } from "../../utils/common.utils";
import { UserContext } from "../UserContext/UserContextProvider";
import { useLocation } from "react-router-dom";
import * as Clevertap from "../../Clevertap/events";
import {
  getGlobalData,
  getPageVisitedData,
} from "../../Clevertap/EventHandler.ts";
import { PAGE_VISITED_EVENT } from "../../Clevertap/EventNames.ts";
var uniqid = require("uniqid");

export const AnalyticsContext = createContext();

function getSessonId(uid) {
  return uniqid(`${uid}-`);
}

export default function AnalyticsContextProvider(props) {
  const { user, userBasicDetails: userInfo } = useContext(UserContext);
  const { pathname, search = "", hash = "" } = useLocation();

  useEffect(() => {
    addCleverTapAnalytics(
      PAGE_VISITED_EVENT,
      getPageVisitedData({
        Platform: "web",
        URL: window.location.href,
      })
    );
  }, [pathname]);

  async function addCleverTapAnalytics(eventName, data = {}) {
    try {
      let baseData = {
        ...getGlobalData({
          Page: window.location.pathname,
          URL: window.location.href,
        }),
      };
      let wholeData = { ...baseData, ...data };

      // console.log(eventName, wholeData, userInfo);
      // isProd &&
      //   window.location.hostname.includes("docflix.com") &&
      Clevertap.eventPush(eventName, wholeData);
      return;
    } catch (error) {
      console.error(error);
    }
  }

  async function addGAWithUserInfo(eventName, data = {}) {
    try {
      // console.log(eventName, data, userInfo);
      // return;
      if (!user || !userInfo) {
        console.error("No UsrInfo Found");
        return;
      }
      let baseData = {
        userId: user.uid,
        profession: userInfo.profession,
        speciality: userInfo.speciality,
        country: userInfo.country,
        state: userInfo.state,
        city: userInfo.city,
        date: new Date(),
        dateTimeStamp: new Date().getTime(),
      };
      let wholeData = { ...baseData, ...data };
      // console.log(eventName, data, userInfo);
      isProd &&
        window.location.hostname.includes("docflix.com") &&
        analytics.logEvent(eventName, wholeData);
    } catch (error) {
      console.error(error);
    }
  }

  async function addGAWithNoUser(eventName, data = {}) {
    try {
      let baseData = {
        date: new Date(),
        dateTimeStamp: new Date().getTime(),
      };
      let wholeData = { ...baseData, ...data };
      // console.log(eventName, data, userInfo);
      isProd &&
        window.location.hostname.includes("docflix.com") &&
        analytics.logEvent(eventName, wholeData);
    } catch (error) {
      console.error(error);
    }
  }

  const SearchEvent = async (
    eventype,
    index,
    eventName,
    objectIDs,
    queryId = "queryid",
    position
  ) => {
    return;
    if (!userInfo) {
      console.error("No UsrInfo Found");
      return;
    }

    let obj = {};
    if (
      eventName === algoliaCustomEvents.searchView ||
      eventName === algoliaCustomEvents.searchClick
    ) {
      obj = {
        userToken: user.uid,
        index: index,
        eventName: eventName,
        objectIDs: objectIDs,
        queryId: queryId,
        positions: [position],
      };
    } else {
      obj = {
        userToken: user.uid,
        index: index,
        eventName: eventName,
        objectIDs: objectIDs,
      };
    }

    try {
      aa(eventype, obj);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        addGAWithUserInfo,
        addGAWithNoUser,
        SearchEvent,
        addCleverTapAnalytics,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
}
