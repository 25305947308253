import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import {
  SpeakerContextProvider,
  VideoPlayerContextProvider,
  UserContextProvider,
  MediaMetadataContextProvider,
  RatingContextProvider,
  LikeContextProvider,
  AuthContextProvider,
  WebinarContextProvider,
  VideoContextProvider,
  ThemeProvider,
  AnalyticsContextProvider,
  NetworkContextProvider,
  ConstantDataContextProvider,
  EmployeeDashboardProvider,
  OurExpertsProvider,
} from "./contexts";

import "react-phone-number-input/style.css";
import smoothscroll from "smoothscroll-polyfill";
import axios from "axios";
import { BACKSTAGE_COLLECTION } from "./AppConstants/CollectionConstants";
import { firestore } from "./firebase/firebase";
import RestrictedMessage, {
  Loader,
} from "./components/RestrictedMessage/RestrictedMessage";
import ModalContextProvider from "./contexts/ModalContext/ModalContextProvider";
import AvatarContextProvider from "./contexts/AvatarContext/AvatarContextProvider";
import "./styling/global.css";
import { BrowserRouter } from "react-router-dom";

smoothscroll.polyfill();

const MainComponent = ({ children }) => {
  const [restrictedCities, setRestrictedCities] = useState({
    loading: true,
    data: null,
    access: false,
  });

  // useEffect(() => {
  //   checkRestrictedCity()
  //     .then(() => {})
  //     .catch(() => {});
  // }, []);

  const checkRestrictedCity = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let documentSnapshot = await firestore
          .collection(BACKSTAGE_COLLECTION)
          .doc("restrictedAreas")
          .get();
        let data = documentSnapshot.data().data || [];
        let allowedCheck = documentSnapshot.data().allowedCheckWeb || false;

        if (allowedCheck) {
          let res = await axios.get(
            "https://asia-south1-docflix-d8cab.cloudfunctions.net/geolocation"
          );
          let { city } = res.data;

          let _shouldGiveAccess = false;

          for (let _j = 0; _j < data.length; _j++) {
            let i = data[_j];
            if (
              i.allowed &&
              i.title.toLowerCase().includes(city.toLowerCase())
            ) {
              _shouldGiveAccess = true;
              break;
            }
          }
          setRestrictedCities({
            loading: false,
            data: documentSnapshot.data().data,
            access: _shouldGiveAccess,
          });
        } else {
          setRestrictedCities({
            loading: false,
            data: documentSnapshot.data().data,
            access: true,
          });
        }
        resolve();
      } catch (error) {
        setRestrictedCities({
          loading: false,
          data: [],
          access: true,
        });
        console.error(error);
        reject(error);
      }
    });
  };

  // return restrictedCities.loading ? (
  //   <Loader />
  // ) : !restrictedCities.access ? (
  //   <RestrictedMessage />
  // ) : (
  return (
    <ThemeProvider>
      <UserContextProvider>
        <ConstantDataContextProvider>
          <AnalyticsContextProvider>
            <AuthContextProvider>
              <SpeakerContextProvider>
                <NetworkContextProvider>
                  <WebinarContextProvider>
                    <VideoContextProvider>
                      <LikeContextProvider>
                        <RatingContextProvider>
                          <MediaMetadataContextProvider>
                            <VideoPlayerContextProvider>
                              <ModalContextProvider>
                                <AvatarContextProvider>
                                  <EmployeeDashboardProvider>
                                    <OurExpertsProvider>
                                      {children}
                                    </OurExpertsProvider>
                                  </EmployeeDashboardProvider>
                                </AvatarContextProvider>
                              </ModalContextProvider>
                            </VideoPlayerContextProvider>
                          </MediaMetadataContextProvider>
                        </RatingContextProvider>
                      </LikeContextProvider>
                    </VideoContextProvider>
                  </WebinarContextProvider>
                </NetworkContextProvider>
              </SpeakerContextProvider>
            </AuthContextProvider>
          </AnalyticsContextProvider>
        </ConstantDataContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
  // );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
    <MainComponent>
      <App />
    </MainComponent>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);
