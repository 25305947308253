import _get from './get';
import _post from './post';
import _put from './put';
import _delete from './delete';

// Import Managers
import UserMediaMetadataManager from './UserMediaMetadata';
import UserMetadataManager from './Metadata';
import NotesManager from './Notes';
import AvatarManager from './Avatar';

const UserManager = {
	_get,
	_post,
	_put,
	_delete,
	UserMediaMetadataManager,
	UserMetadataManager,
	NotesManager,
	AvatarManager
};

export default UserManager;
