import { createContext, useContext, useEffect, useRef, useState } from "react";
import { WEBINAR_REGISTER_COLLECTION } from "../../AppConstants/CollectionConstants";
import { DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";
import { firestore } from "../../firebase/firebase";
import { UserContext } from "../UserContext/UserContextProvider";
// import closeImg from "../../../../assets/images/close.png";
import eventSpeaker1 from "../../assets/images/networking/1.png";
import eventSpeaker2 from "../../assets/images/networking/2.png";
import eventSpeaker3 from "../../assets/images/networking/3.png";
import eventSpeaker4 from "../../assets/images/networking/4.png";
import { removeNotification } from "../../Managers/Chat";
import { usersAPI } from "../../apis/usersAPI";

export const networkContext = createContext();

const networkingList1 = [
  {
    name: "Dr.Raghu Satyanarayan",
    designation: "",
    city: "Noida",
    photoUrl: eventSpeaker1,
    common: 2,
    events: 8,
  },
  {
    name: "Dr.Adarsh Seth",
    designation: "",
    city: "Mumbai",
    photoUrl: eventSpeaker2,
    common: 4,
    events: 6,
  },
  {
    name: "Dr.Jay Shah",
    designation: "",
    city: "Kolkata",
    photoUrl: eventSpeaker3,
    common: 8,
    events: 3,
  },
  {
    name: "Dr. Rama K. Shetty",
    designation: "",
    city: "Delhi",
    photoUrl: eventSpeaker4,
    common: 5,
    events: 7,
  },
];
const limit = 10;
export default function NetworkContextProvider(props) {
  const { user, toggleChatSection } = useContext(UserContext);

  const [chatUser, setChatUser] = useState(null);
  const [roomId, setRoomId] = useState("");
  const [networkingUserData, setNetworkingUserData] = useState([]);
  const [webinarDetails, setWebinarDetails] = useState("");
  const [showMoreUser, setShowMoreUser] = useState(0);
  const [showMoreBtnStatus, setShowMoreBtnStatus] = useState(false);
  const lastDocRef = useRef(null);

  useEffect(() => {
    getNetworkingData();
  }, [webinarDetails, showMoreUser]);

  const getSortedWebinars = async (
    webinarID,
    based_on = TIMESTAMP,
    order = DESCENDING,

    ignoreLimit = false
  ) => {
    try {
      let docRef = firestore
        .collection(WEBINAR_REGISTER_COLLECTION)
        .doc(webinarID)
        .collection("webinrRegisteredUsers");

      // docRef = docRef.where("webinars", "array-contains", webinarID);

      // docRef = docRef.orderBy(based_on, order);
      if (!ignoreLimit) docRef = docRef.limit(limit);

      if (lastDocRef.current) docRef = docRef.startAfter(lastDocRef.current);
      const query = await docRef.get();

      if (query.empty) {
        setShowMoreBtnStatus(true);

        return [];
      }

      // Updating last docRef
      let newDAtarefset = query.docs[query.docs.length - 1];

      lastDocRef.current = newDAtarefset;

      let _data = [];
      query.docs.forEach((doc) => {
        _data.push(doc.data());
      });
      return { data: _data, lastDocRef };
    } catch (error) {
      console.error("Error :: ", error);
      throw error;
    }
  };

  function getRoomIdWithUser(currentUserId, otherUserId) {
    let id = "";
    if (currentUserId > otherUserId) {
      id = currentUserId + "+" + otherUserId;
    } else {
      id = otherUserId + "+" + currentUserId;
    }
    return id;
  }

  const handleShowMore = () => {
    setShowMoreUser(showMoreUser + 1);
    setTimeout(() => {
      let element = document.getElementById("chatSectionDiv");
      element.scrollTo(0, element.scrollHeight);
    }, 1000);
  };

  const getNetworkingData = async (limit = 5, ignoreLimit = false) => {
    if (!user) {
      return;
    }
    if (webinarDetails && webinarDetails !== "") {
      let data = await getSortedWebinars(
        webinarDetails.id,
        "startTime",
        DESCENDING,
        null,
        limit,
        ignoreLimit
      );

      // console.log("data:", data);
      // let commonCount = {};
      // let users = data.data.map((_data) => {
      //   let { id } = _data;

      //   if (_data.webinars && _data.webinars.length) {
      //     _data.webinars.forEach((_wid) => {
      //       if (userMetadata.webinars.indexOf(_wid) !== -1) {
      //         if (commonCount.hasOwnProperty(id)) {
      //           commonCount[id] += 1;
      //         } else {
      //           commonCount[id] = 1;
      //         }
      //       }
      //     });
      //   }
      //   // return UserManager._get.getUser(userId);
      // });

      // let _users = users.map((_uData) => ({
      //   ..._uData,
      //   commonCount: commonCount[_uData.id],
      //   photoUrl:
      //     networkingList1[Math.floor(Math.random() * networkingList1.length)]
      //       .photoUrl,
      // }));

      const datanew =
        data.data
          ?.filter((x) => x.id != user.uid)
          .sort((a, b) =>
            a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
          ) || [];

      setNetworkingUserData([...networkingUserData, ...datanew]);

      // setNetworkingUserData(data.data);
    }

    // return data.data;
    // setNetworkingList();
  };

  const updateRoomId = (roomId) => {
    setRoomId(roomId);
  };

  const getNetworkingNewData = async (webinarID) => {
    try {
      if (webinarID && user.uid) {
        const res = await usersAPI.getUsersInArea({
          eventId: webinarID,
        });
        if (res.data.status === "Successfull") {
          let _users = res.data.data.matches.map((_uData) => ({
            ..._uData,
            photoUrl:
              networkingList1[
                Math.floor(Math.random() * networkingList1.length)
              ].photoUrl,
          }));
          return _users;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openChat = (webinarDetails_id, sendUserData) => {
    toggleChatSection(true);
    setChatUser(sendUserData);
    let generatedRoomId = getRoomIdWithUser(user.uid, sendUserData.id);
    updateRoomId(generatedRoomId);
    removeNotification(webinarDetails_id, user.uid, sendUserData.id);
  };

  return (
    <networkContext.Provider
      value={{
        getNetworkingData,
        getRoomIdWithUser,
        roomId,
        updateRoomId,
        openChat,
        chatUser,
        setChatUser,
        networkingUserData,
        setWebinarDetails,
        handleShowMore,
        showMoreBtnStatus,
      }}
    >
      {props.children}
    </networkContext.Provider>
  );
}
