import { createContext, useContext, useReducer } from "react";
import EmployeeManager from "../../Managers/Employee";
import { useHistory } from "react-router-dom";

export const EmployeeDashboardContext = createContext();

export function useEmployeeDashboard() {
  return useContext(EmployeeDashboardContext);
}

const initialState = {
  isLoading: false,
  employeeData: null,
  teamsData: null,
  doctorsData: {
    data: null,
    queryMetadata: null,
    pages: {
      0: undefined,
    },
    currentPage: 0,
  },
  isFetchingDoctorData: false,
  overallAnalytics: null,
  isfieldAgentDataLoading: false,
  fieldAgentData: {},
  position: "",
};

const Action_Type = {
  updateData: "updateData",
  handleError: "handleError",
  loading: "loading",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.updateData:
    case Action_Type.handleError:
    case Action_Type.loading:
      return { ...state, ...payload };

    default:
      return { ...state };
  }
};

export default function EmployeeDashboardProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const getEmployeeData = async (obj, cb = () => {}) => {
    try {
      dispatch({
        type: Action_Type.updateData,
        payload: { isLoading: true },
      });

      let res = await EmployeeManager._get.getEmployeeData(obj);

      dispatch({
        type: Action_Type.updateData,
        payload: { ...res, isLoading: false },
      });

      cb(res);
    } catch (error) {
      console.error(error);
      history.replace("/");

      cb(false);

      dispatch({
        type: Action_Type.updateData,
        payload: { isLoading: false },
      });
    }
  };

  const getALLTeams = async ({ number }) => {
    try {
      if (!state.position || !state["employeeData"]["employeeCode"]) {
        getEmployeeData({ number }, async (res) => {
          if (res) {
            await getTeams({ predefinedData: res, number: number });
            return;
          }
        });
      } else {
        await getTeams({ predefinedData: null, number: number });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Action_Type.updateData,
        payload: { isLoading: false },
      });
    }
  };

  const getALLDoctors = async ({ number }) => {
    try {
      if (!state.position || !state["employeeData"]["employeeCode"]) {
        getEmployeeData({ number }, async (res) => {
          if (res) {
            await getDoctors({ predefinedData: res });
            return;
          }
        });
      } else {
        await getDoctors({ predefinedData: null });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Action_Type.updateData,
        payload: { isLoading: false },
      });
    }
  };

  const getDoctors = async ({ predefinedData }) => {
    dispatch({
      type: Action_Type.updateData,
      payload: { isFetchingDoctorData: true },
    });

    let obj = {
      employeeId: "",
      position: "",
      memberId: null,
      memberPosition: null,
      search: null,
      pageToken:
        state.doctorsData["pages"][state.doctorsData.currentPage] || null,
    };

    if (predefinedData) {
      obj = {
        ...obj,
        position: predefinedData.position,
        employeeId: predefinedData["employeeData"]["employeeCode"],
      };
    } else {
      obj = {
        ...obj,
        position: state.position,
        employeeId: state["employeeData"]["employeeCode"],
      };
    }

    let res = await EmployeeManager._get.getDoctorsData(obj);
    let newData = state.doctorsData.data ? [...state.doctorsData.data] : [];
    let newArray = newData.concat(res.rows);
    let updatedPage = state.doctorsData.currentPage + 1;

    const payload = {
      doctorsData: {
        ...state.doctorsData,
        data: newArray,
        queryMetadata: res.queryMetadata,
        pages: {
          ...state.doctorsData.pages,
          [updatedPage]: res.queryMetadata.pageToken,
        },
        currentPage: updatedPage,
      },
      isFetchingDoctorData: false,
    };
    dispatch({
      type: Action_Type.updateData,
      payload: payload,
    });
  };

  const getTeams = async ({ predefinedData, number }) => {
    dispatch({
      type: Action_Type.updateData,
      payload: { isLoading: true },
    });

    let obj = {
      orderBy: "",
      limit: false,
      equalTo: "",
      number: number,
    };

    if (predefinedData) {
      obj = {
        ...obj,
        orderBy: predefinedData.position,
        equalTo: predefinedData["employeeData"]["employeeCode"],
      };
    } else {
      obj = {
        ...obj,
        orderBy: state.position,
        equalTo: state["employeeData"]["employeeCode"],
      };
    }

    let res = await EmployeeManager._get.getTeamsData(obj);

    dispatch({
      type: Action_Type.updateData,
      payload: {
        teamsData: res,
        isLoading: false,
      },
    });
  };

  const getFieldAgentData = async (
    employeeCode,
    employeeNumber,
    fetchOnlyDoctorsData = false
  ) => {
    try {
      let _fieldAgentTeamDataArrayObj = {};
      let overallAnalytics = {};
      let _currentEmployee = state.fieldAgentData[employeeNumber] || {};
      let memberPosition = fetchOnlyDoctorsData
        ? _currentEmployee.position
        : "";

      if (!fetchOnlyDoctorsData) {
        dispatch({
          type: Action_Type.updateData,
          payload: { isfieldAgentDataLoading: true },
        });
        const { key, value } = await EmployeeManager._get.getPosition(
          employeeCode
        );
        memberPosition = value;
        // position - value, key= employeeCode
        overallAnalytics = await EmployeeManager._get.getOverallAnalytics(
          state["employeeData"]["employeeCode"],
          state.position,
          employeeCode,
          memberPosition
        );

        let _fieldAgentTeamsDataArray = Object.values(state.teamsData).filter(
          (t) => t[memberPosition] == employeeCode
        );

        for (let index = 0; index < _fieldAgentTeamsDataArray.length; index++) {
          _fieldAgentTeamDataArrayObj[
            _fieldAgentTeamsDataArray[index].formattedPhoneNumber
          ] = _fieldAgentTeamsDataArray[index];
        }

        overallAnalytics["fullName"] =
          _fieldAgentTeamDataArrayObj[employeeNumber]?.fullName;
        overallAnalytics["jobClassification"] =
          _fieldAgentTeamDataArrayObj[employeeNumber]?.jobClassification;

        delete _fieldAgentTeamDataArrayObj[employeeNumber];
      }

      dispatch({
        type: Action_Type.updateData,
        payload: { isFetchingDoctorData: true },
      });

      // position - value, key= employeeCode
      let _doctorsData = await EmployeeManager._get.getDoctorsData({
        employeeId: state["employeeData"]["employeeCode"],
        position: state.position,
        memberId: employeeCode,
        memberPosition: memberPosition,
        search: null,
        pageToken:
          _currentEmployee?.["doctorsData"]?.["pages"][
            _currentEmployee?.["doctorsData"]?.currentPage
          ] || undefined,
      });
      let newData = _currentEmployee?.["doctorsData"]?.data
        ? [..._currentEmployee?.["doctorsData"]?.data]
        : [];
      let newArray = newData.concat(_doctorsData.rows);
      let updatedPage = _currentEmployee?.["doctorsData"]?.currentPage + 1 || 0;

      let _newPayload = {};

      if (fetchOnlyDoctorsData) {
        _newPayload = {
          isfieldAgentDataLoading: false,
          isFetchingDoctorData: false,
          fieldAgentData: {
            ...state.fieldAgentData,
            [employeeNumber]: {
              ..._currentEmployee,
              doctorsData: {
                ..._currentEmployee.doctorsData,
                data: newArray,
                queryMetadata: _doctorsData.queryMetadata,
                pages: {
                  ..._currentEmployee?.["doctorsData"]?.pages,
                  [updatedPage]: _doctorsData.queryMetadata.pageToken,
                },
                currentPage: updatedPage,
              },
            },
          },
        };
      } else {
        _newPayload = {
          isfieldAgentDataLoading: false,
          isFetchingDoctorData: false,
          fieldAgentData: {
            ...state.fieldAgentData,
            [employeeNumber]: {
              ..._currentEmployee,
              position: memberPosition,
              employeeCode: employeeCode,
              teamsData: _fieldAgentTeamDataArrayObj,
              doctorsData: {
                ..._currentEmployee?.doctorsData,
                data: newArray,
                queryMetadata: _doctorsData.queryMetadata,
                pages: {
                  ..._currentEmployee?.["doctorsData"]?.pages,
                  [1]: _doctorsData?.queryMetadata?.pageToken,
                },
                currentPage: 1,
              },
              overallAnalytics: overallAnalytics,
            },
          },
        };
      }

      dispatch({
        type: Action_Type.updateData,
        payload: _newPayload,
      });
      return _newPayload;
    } catch (error) {
      console.error(error);
      dispatch({
        type: Action_Type.updateData,
        payload: { isfieldAgentDataLoading: false },
      });
    }
  };

  const value = {
    state,
    getEmployeeData,
    getALLTeams,
    getFieldAgentData,
    getALLDoctors,
    getDoctors,
  };

  return (
    <EmployeeDashboardContext.Provider value={value}>
      {children}
    </EmployeeDashboardContext.Provider>
  );
}
