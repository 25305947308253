import { createContext, useEffect, useState } from "react";
import { closeFullscreen, openFullscreen } from "../../utils/common.utils";

export const VideoPlayerContext = createContext([]);

export default function VideoPlayerContextProvider(props) {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    // window.addEventListener("keydown", (e) => {
    //   if (e.key === "Escape") {
    //     setIsFullScreen((prev) => prev && false);
    //   }
    // });
    if (document.addEventListener) {
      document.addEventListener("fullscreenchange", exitHandler, false);
      document.addEventListener("mozfullscreenchange", exitHandler, false);
      document.addEventListener("MSFullscreenChange", exitHandler, false);
      document.addEventListener("webkitfullscreenchange", exitHandler, false);
    }

    function exitHandler() {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement !== null
      ) {
        // Run code on exit
        setIsFullScreen((prev) => prev && false);
      }
    }
    // return () => window.removeEventListener("keydown");
  }, []);

  const handleFullScreen = () => {
    if (!isFullScreen) {
      openFullscreen();
      setIsFullScreen(true);
    } else {
      closeFullscreen();
      setIsFullScreen(false);
    }
  };

  return (
    <VideoPlayerContext.Provider value={{ isFullScreen, handleFullScreen }}>
      {props.children}
    </VideoPlayerContext.Provider>
  );
}
